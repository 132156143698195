/* Text classes, the new way
   Modifiers can change the style of the default classes, eg:
   <h1 class="brix-h2-style brix-text-red brix-fw-bold"></h1>
   ========================================================================== */

/* Font files imports
   ========================================================================== */

/* NOTE: In your project please redefine the url variables to point to
   the actual src path of your fonts. The variables below have a default flag.
   These declarations need to be here for the reason above.
 */

@font-face {
    font-family: Vodafone;
    src: url($font-url-rg-eot);

    /* IE 9 - 11 */
    src: url($font-url-rg-woff2) format('woff2'), url($font-url-rg-woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vodafone;
    src: url($font-url-bd-eot);

    /* IE 9 - 11 */
    src: url($font-url-bd-woff2) format('woff2'), url($font-url-bd-woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Vodafone;
    src: url($font-url-lt-eot);

    /* IE 9 - 11 */
    src: url($font-url-lt-woff2) format('woff2'), url($font-url-lt-woff) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

/* Body text
   ========================================================================== */
.#{$ws10-css-prefix}-text {
    @include ws10-text;
}
.#{$ws10-css-prefix}-text-inverse {
    @include ws10-text-inverse;
}

.#{$ws10-css-prefix}-text-large {
    @include ws10-text-large;
}
.#{$ws10-css-prefix}-text-large-inverse {
    @include ws10-text-large-inverse;
}

.#{$ws10-css-prefix}-text-small {
    @include ws10-text-small;
}
.#{$ws10-css-prefix}-text-small-inverse {
    @include ws10-text-small-inverse;
}

.#{$ws10-css-prefix}-text-smaller {
    @include ws10-text-smaller;
}
.#{$ws10-css-prefix}-text-smaller-inverse {
    @include ws10-text-smaller-inverse;
}

.#{$ws10-css-prefix}-text-strike {
    text-decoration: $text-decoration-strike-through;
}

.#{$ws10-css-prefix}-text-italic {
    font-style: italic;
}

.#{$ws10-css-prefix}-text-bold {
    font-weight: $font-weight-bold;
}

.#{$ws10-css-prefix}-text-light {
    font-weight: $font-weight-light;
}

.#{$ws10-css-prefix}-uppercase {
    text-transform: uppercase;
}

.#{$ws10-css-prefix}-bold-positive {
    color: $color-secondary-500;
    font-weight: $font-weight-bold;
}

.#{$ws10-css-prefix}-bold-positive-inverse {
    color: $color-secondary-500-inverse;
    font-weight: $font-weight-bold;
}

.#{$ws10-css-prefix}-text-left {
    text-align: left;
}

.#{$ws10-css-prefix}-text-center {
    text-align: center;
}

.#{$ws10-css-prefix}-text-justify {
    text-align: justify;
}

@each $viewport, $val in $breakpoints {
    @include mq($viewport) {
        .#{$ws10-css-prefix}-text-center--#{$viewport} {
            text-align: center;
        }
    }
}
