/**
 * @tokens Typography
 * @presenter FontFamily
 */

$font-vodafone: 'Vodafone', 'Arial', sans-serif;

/**
 * @tokens Typography
 * @presenter FontSize
 */
$font-size-25: 12px;
$font-size-50: 14px;
$font-size-100: 16px;
$font-size-150: 18px;
$font-size-200: 20px;
$font-size-300: 24px;
$font-size-400: 30px;
$font-size-500: 34px;
$font-size-700: 48px;
$font-size-750: 56px;
$font-size-900: 80px;
$font-size-1000: 140px;

/**
 * @tokens-end
 */

$font-sizes: (
    '25': $font-size-25,
    '50': $font-size-50,
    '100': $font-size-100,
    '150': $font-size-150,
    '200': $font-size-200,
    '300': $font-size-300,
    '400': $font-size-400,
    '500': $font-size-500,
    '700': $font-size-700,
    '750': $font-size-750,
    '900': $font-size-900,
    '1000': $font-size-1000,
);

/**
 * @tokens Typography
 * @presenter LineHeight
 */

$line-height-25: 1.125;
$line-height-50: 1.25;
$line-height-100: 1.5;
$line-height-reset: 1;
$line-height-fix-150: 24px;
$line-height-fix-200: 32px;

/**
 * @tokens-end
 */

$line-heights: (
    '25': $line-height-25,
    '50': $line-height-50,
    '100': $line-height-100,
    'reset': $line-height-reset,
);
$line-heights-fix: (
    '150': $line-height-fix-150,
    '200': $line-height-fix-200,
);

/**
 * @tokens Typography
 * @presenter FontWeight
 */

$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-bold: 700;

/**
 * @tokens-end
 */

$font-weights: (
    'light': $font-weight-light,
    'normal': $font-weight-normal,
    'bold': $font-weight-bold,
);

/**
 * @tokens Typography
 */

$text-decoration-underline: underline;
$text-decoration-strike-through: line-through;
$text-decoration-none: none;

/**
 * @tokens-end
 */

$text-decorations: (
    'underline': $text-decoration-underline,
    'strike-through': $text-decoration-strike-through,
    'none': $text-decoration-none,
);

/**
  * https://css-tricks.com/snippets/sass/strip-unit-function/
  *
  * Remove the unit of a length
  * @param {Number} $number - Number to remove unit from
  * @return {Number} - Unit less number
  * @TODO if we keep that, move to style/helper or something
 */
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}
