// import only precss from styles package! This contains all your variables you need!
@import '../styles/scss/precss/precss';
@import '../icon-text/icon-text';
@import '../form-selection-control/form-selection-control';

$promotional-summary-card-class-prefix: $ws10-css-prefix + '-promotional-summary-card';
$checkbox-width: 20px;

.#{$promotional-summary-card-class-prefix} {
    $checkbox-width: 20px;
    $checkbox-border: 1px;

    &__headline {
        margin-bottom: $space-50;

        @include mq('small') {
            margin-bottom: $space-100;
        }
    }

    &__card {
        background-color: $color-monochrome-200;
    }

    &__list-items {
        padding: $space-125;
        display: flex;
        flex-direction: column;
        row-gap: $space-100;
    }

    .#{$form-selection-control-class-prefix}__item {
        padding-bottom: 0;
        padding-left: calc(($size-icon-200 - $checkbox-width - $checkbox-border) / 2);
    }
}
