/** @define tariff-card */
@import '../styles/scss/precss/precss';

$tariff-card-class-prefix: 'ws10-tariff-card';

.#{$tariff-card-class-prefix} {
    @include ws10-text(false);

    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    min-height: 100%;

    &__wrapper {
        display: flex;
        max-width: 100%;
    }

    &__item {
        height: 100%;
        width: 100%;
        min-height: 100%;
        position: relative;
    }

    &--has-flag-badge {
        .#{$tariff-card-class-prefix}__item {
            padding-top: get-custom-property('flag-badge-height');
        }

        position: relative;
    }

    &__item.#{$tariff-card-class-prefix}__without-flag-badge {
        padding-top: 0;

        @include mq('medium') {
            padding-top: get-custom-property('flag-badge-height');
        }
    }

    &__flag-badge {
        position: absolute;
        top: 0;
    }

    &__card-container {
        @include card-base;

        display: flex;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        height: 100%;
        width: 100%;

        &--with-flagbadge {
            border-top-left-radius: 0;
        }
    }

    &__headline--top-small {
        @include ws10-h4;

        margin-bottom: $space-75;
    }

    &__headline {
        margin-bottom: $space-100;
        font-size: $font-size-700;
        font-weight: $font-weight-bold;
        line-height: $line-height-fix-200;

        @include mq(small) {
            margin-bottom: $space-150;
        }
    }

    &__txt-content {
        margin-left: -$space-100;
        margin-right: -$space-100;
        font-size: $font-size-100;
        height: 100%;
        width: calc(100% + 2 * #{$space-100});
        background-color: $color-monochrome-200;
        padding: $space-100 $space-100;

        :nth-child(2n):not(:last-child) {
            margin-bottom: $space-50;
        }

        .ws10-numbered-list {
            li {
                padding-left: $space-100;
            }
        }

        .ws10-checkmark-list,
        .ws10-bullet-list,
        .ws10-hook-list,
        .ws10-numbered-list {
            margin-bottom: 0;
        }

        ul > :last-child,
        ol > :last-child {
            margin-bottom: 0;
        }

        li::marker {
            font-weight: $font-weight-bold;
        }

        @include mq(small) {
            margin-left: -$space-125;
            margin-right: -$space-125;
            font-size: $font-size-150;
            margin-bottom: $space-150;
            width: calc(100% + 2 * #{$space-125});

            li {
                font-size: $font-size-150;
                margin-bottom: $space-75;
            }

            :nth-child(2n):not(:last-child) {
                margin-bottom: $space-75;
            }
        }
    }

    &__promo-price {
        margin-bottom: $space-100;

        @include mq(small) {
            margin-bottom: $space-150;
        }
    }

    &__action-button-container {
        margin-bottom: $space-100;
        text-align: center;
        width: 100%;

        @include mq(small) {
            margin-bottom: $space-150;
        }

        :nth-child(2) {
            margin-top: $space-75;

            @include mq(small) {
                margin-top: $space-100;
            }
        }
    }

    &__button-link-container {
        text-align: center;

        > :nth-child(2) {
            margin-top: $space-75;

            @include mq(small) {
                margin-top: $space-100;
            }
        }
    }
}
