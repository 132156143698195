@import '../styles/scss/precss/precss';

$promo-price-class-prefix: $ws10-css-prefix + '-promo-price';
$promo-price-sup-font-size-small: .8em;
$promo-price-sup-font-size-medium: .9em;

// the following value makes sure the line-height matches the capital letter height of the 'Vodafone' font
$promo-price-line-height-cap-height: .7;

.#{$promo-price-class-prefix} {
    @include ws10-text(false);

    position: relative;
    line-height: $promo-price-line-height-cap-height;

    &--left {
        text-align: left;
    }

    &--right {
        text-align: right;
    }

    &--center {
        text-align: center;
    }

    &__strike-price {
        display: block;
        font-weight: $font-weight-bold;
        color: $color-primary-200;
        text-decoration: $text-decoration-strike-through;
        font-size: $font-size-150;
        margin-bottom: $space-50;
    }

    &__price-container {
        white-space: nowrap;
    }

    &__condition-before {
        font-weight: $font-weight-bold;
        font-size: $font-size-400;
    }

    &__price {
        font-weight: $font-weight-bold;
        font-size: $font-size-700;
        word-spacing: -.1em;
    }

    &__currency {
        font-size: $font-size-400;
    }

    &__condition-after {
        font-weight: $font-weight-normal;
        padding-top: $space-75;
        font-size: $font-size-150;

        sup {
            // don't let a footnote increase the line-height of the parent element
            line-height: 0;
        }
    }

    &--small {
        .#{$promo-price-class-prefix} {
            &__price {
                font-size: $font-size-500;
            }

            &__condition-after {
                sup {
                    font-size: $promo-price-sup-font-size-small;
                }
            }

            &__condition-before,
            &__currency {
                font-size: $font-size-300;
            }
        }
    }

    &--inverse {
        color: $color-monochrome-600-inverse;

        .#{$promo-price-class-prefix} {
            &__strike-price {
                color: $color-primary-200-inverse;
            }
        }
    }
}
