@import '../styles/scss/precss/precss';
@import '../button/button';

$text-header-class-prefix: $ws10-css-prefix + '-text-header';

.#{$text-header-class-prefix} {
    @include ws10-text(false);

    border-bottom: $border-width-small $border-style-solid $color-monochrome-300;

    &__container {
        padding: $space-150 $zero;

        @include mq(medium) {
            padding: $space-200 $zero;
        }
    }

    &--dark-red {
        background-color: $color-primary-300;
        h1 ,h2, .#{$text-header-class-prefix}__price-table--color {
            color: $color-monochrome-600-inverse;
        }
    }

    &--red-gradiant {
        background: $color-gradient-100;
        h1 ,h2, .#{$text-header-class-prefix}__price-table--color {
            color: $color-monochrome-600-inverse;
        }
    }

    &--light-grey {
        background-color: $color-monochrome-200;
    }

    &--white {
        background-color: $color-monochrome-100;
    }

    &__headline {
        color: $color-monochrome-600;
        margin-bottom: $space-0;
    }

    &__subline {
        color: $color-monochrome-500;
        margin-bottom: $space-0;

        @include mq(small) {
            padding-right: $space-150;
        }

        @include mq(medium) {
            padding-right: $space-200;
        }
    }

    &__container-price-and-button {
        display: flex;
        margin-left: auto;

        @include mq(mobile-only) {
            display: block;
            width: 100%;
        }

        &--with-price {
            @include mq(mobile-only) {
                margin-top: $space-150;
            }
        }
    }

    &__container-content {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include mq(mobile-only) {
            display: block;
        }

        &--with-subline {
            margin-top: $space-50;
        }
    }

    &__price-table {
        color: $color-monochrome-600;
        font-size: $font-size-200;
        white-space: nowrap;
        align-self: end;
        display: inline-grid;
        grid-template-columns: auto auto auto;
        column-gap: $space-25;
        row-gap: $space-15;
        line-height: $line-height-50;

        @include mq(small) {
            padding-right: $space-100;
        }

        @include mq(medium) {
            padding-right: $space-150;
        }
    }

    &__price-value {
        font-weight: $font-weight-bold;
        font-size: $font-size-200;
        text-align: right;
    }

    &__price-currency {
        font-weight: $font-weight-bold;
        font-size: $font-size-200;
    }

    &__price-label {
        font-weight: $font-weight-normal;
        font-size: $font-size-200;
        padding: $zero;
    }

    &__button {
        white-space: nowrap;
        padding-bottom: $zero;
        align-self: end;

        @include mq(mobile-only) {
            padding-top: $space-100;
        }
    }
}
