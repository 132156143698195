@import '../styles/scss/precss/precss';

$class-prefix: $ws10-css-prefix + '-feature-card';
$button-checked-color: press($color-monochrome-600);
$button-hover-color: hover($color-monochrome-600);

.#{$class-prefix} {
    @include ws10-text(false);
    @include card-base;

    $border: $border-width-small solid $color-monochrome-300;
    $cta-active-background: $color-primary-100;
    $cta-active-color: $color-monochrome-100;

    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;

    @include mq(small) {
        flex-direction: column;
    }

    &__content {
        text-align: left;
        flex: 0 0 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 60%;

        @include mq(small) {
            text-align: center;
            flex-basis: auto;
            flex-grow: 1;
            max-width: 100%;
        }
    }

    &__headline {
        margin-bottom: $space-50;

        @include mq(small) {
            margin-bottom: $space-100;
        }

        .#{$ws10-css-prefix}-headline {
            margin-bottom: 0;
        }
    }

    &__img-wrapper {
        position: relative;
        overflow: hidden;

        $flex-width: calc(40% - #{$space-75});

        flex: 0 0 $flex-width;
        margin-right: $space-75;

        @include mq(small) {
            flex-basis: auto;
            margin-right: 0;
            margin-bottom: $space-100;
            height: 200px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @include mq(small) {
            position: relative;
        }
    }

    &__text-link {
        margin-bottom: $space-75;

        @include mq(small) {
            margin-bottom: $space-150;
        }
    }

    &__highlight-badge {
        margin-bottom: $space-100;
        height: 28px;

        .#{$ws10-css-prefix}-highlight-badge__icon--left,
        .#{$ws10-css-prefix}-highlight-badge__icon--trigger {
            display: none;
        }

        @include mq(small) {
            height: $space-200;

            .#{$ws10-css-prefix}-highlight-badge__icon--left,
            .#{$ws10-css-prefix}-highlight-badge__icon--trigger {
                display: block;
            }
        }
    }

    &__button {
        .#{$ws10-css-prefix}-button {
            padding: 0;

            .#{$ws10-css-prefix}-system-icon {
                display: none;
            }
        }
    }

    &--checked {
        .#{$ws10-css-prefix}-button {
            background: $button-checked-color;
            border-color: $button-checked-color;
            color: $color-monochrome-100;

            @media (hover: hover) {
                &:hover {
                    background: $button-hover-color;
                    border-color: $button-hover-color;
                    color: $color-monochrome-100;
                }
            }

            .#{$ws10-css-prefix}-system-icon {
                display: block;
                // stylelint-disable-next-line declaration-no-important
                color: $color-monochrome-100 !important;
            }
        }
    }

    &--unselectable {
        margin-top: auto;

        .#{$ws10-css-prefix}-button {
            background: $button-checked-color;
            border-color: $button-checked-color;
            color: $color-monochrome-100;

            @media (hover: hover) {
                &:hover {
                    cursor: not-allowed;
                }
            }

            .#{$ws10-css-prefix}-system-icon {
                display: none;
            }
        }

        .#{$class-prefix}__promo-price {
            display: none;
        }
    }

    &__promo-price {
        margin-top: auto;
        margin-bottom: $space-75;

        @include mq(small) {
            margin-bottom: $space-150;
        }

        .#{$ws10-css-prefix}-promo-price {
            @include mq(small) {
                text-align: center;
            }
        }
    }
}
