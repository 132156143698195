@use 'sass:map';
@import '../styles/scss/precss/precss';
@import '../flag-badge/flag-badge';
@import '../system-icon/system-icon';
@import '../color-swatch/color-swatch';
@import '../highlight-badge/highlight-badge';
@import '../promo-price/promo-price';

$product-card-class-prefix: $ws10-css-prefix + '-product-card-list';

.#{$product-card-class-prefix} {
    @include ws10-text(false);

    .#{$ws10-css-prefix}-column {
        display: flex;
    }

    &__item {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: $space-150;

        &:hover {
            text-decoration: none;
            box-shadow: none;

            .#{$product-card-class-prefix}__content {
                box-shadow: $shadow-large;
            }
        }

        &:active {
            box-shadow: none;

            .#{$product-card-class-prefix}__content {
                box-shadow: $shadow-small;
            }
        }

        &:focus-visible {
            border-color: transparent;
            box-shadow: none;
            outline: 0;

            .#{$product-card-class-prefix}__content {
                @include brix-focus;

                // Remove border color - calculated by js that is why important needed
                // stylelint-disable-next-line declaration-no-important
                border-color: transparent !important;
            }
        }

        &:focus {
            outline: none;

            // remove simplicity focus style
            text-decoration: none;
        }

        @include mq(large) {
            margin-bottom: $space-200;
        }
    }

    &__content {
        @include card-base;

        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        cursor: pointer;

        &-lower-part,
        &-upper-part {
            text-align: center;
        }

        &-lower-part {
            display: inherit;
            flex-direction: column;
            justify-content: end;
            height: 100%;
        }

        &--border {
            border-width: $border-width-small;
            border-style: $border-style-solid;
            border-color: transparent;

            @each $color, $styles in $flag-badge-colors {
                .#{$product-card-class-prefix}__flag-badge:has(.#{$flag-badge-class-prefix}--#{$color}) + & {
                    border-color: map.get($styles, 'background');
                }

                .#{$product-card-class-prefix}__flag-badge:has(.#{$flag-badge-class-prefix}--#{$color}.#{$flag-badge-class-prefix}--inverse) + & {
                    border-color: map.get($styles, 'inverse', 'background');
                }
            }
        }
    }

    &__image {
        height: 200px;
        margin-bottom: $space-100;
        vertical-align: bottom;
    }

    &__headline {
        margin-bottom: $space-100;
    }

    &__color-swatch {
        padding-bottom: $space-150;
    }

    &__highlight-badge {
        padding-bottom: $space-100;
    }

    &__flag-badge {
        align-self: flex-start;

        + .#{$product-card-class-prefix} {
            &__content {
                border-top-left-radius: 0;
            }
        }

        &--full {
            align-self: auto;

            + .#{$product-card-class-prefix} {
                &__content {
                    border-top-right-radius: 0;
                }
            }
        }
    }

    &__corner-icon {
        position: absolute;
        top: $space-75;
        right: $space-75;
    }

    &__text-element {
        display: inline-flex;
        align-self: center;
        padding-top: $space-150;
        padding-bottom: $space-15;
        margin-bottom: 0;
        border-bottom: $border-width-small $border-style-solid transparent;
        color: $color-primary-200;
        transition: color $duration-200 $ease-in-sine, border $duration-200 $ease-in-sine;

        & span {
            line-height: $size-icon-150;
            margin-bottom: $space-0;
        }

        &:hover {
            border-bottom: $border-width-small $border-style-solid $color-primary-200;
        }

        &-icon {
            margin-left: $space-25;
        }
    }

    &__prices {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-end;
        column-gap: $space-150;
        row-gap: $space-100;

        @include mq(small) {
            column-gap: $space-200;
        }
    }

    &__price {
        display: flex;
        text-decoration: none;
        color: $color-monochrome-600;
        align-items: flex-end;
    }

    &__description {
        margin-top: $space-100;
    }
}
