@import '../styles/scss/precss/precss';
@import '../system-icon/system-icon';
@import '@splidejs/splide/css';

$image-gallery-class-prefix: $ws10-css-prefix + '-image-gallery';
$aspect-ratio-w: 9;
$aspect-ratio-h: 10;

.#{$image-gallery-class-prefix} {
    @include ws10-text(false);

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2.5%;
    justify-items: center;

    &-images {
        grid-column: 2 / 12;
        display: none;
        width: 100%;

        @include mq(medium) {
            display: block;
        }

        &__list {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__item {
            flex-basis: 28%;
            flex-shrink: 1;
        }

        &__image {
            max-width: 100%;
            vertical-align: middle;
        }
    }

    &-icon {
        grid-column: 11 / 13;

        @include mq(medium) {
            grid-column: 12 / 13;
        }
    }

    //  change size to 250 when it is avaiable
    &__icon {
        $icon-color: currentcolor;

        width: $size-icon-250;
        height: $size-icon-250;
        color: $icon-color;

        @include mq(medium) {
            width: $size-icon-300;
            height: $size-icon-300;
        }
    }

    @mixin slider($root) {
        position: relative;
        outline: none;

        &--draggable {
            > #{$root}__track {
                > #{$root}__list {
                    > #{$root}__slide {
                        user-select: none;
                    }
                }
            }
        }

        &--fade {
            > #{$root}__track {
                > #{$root}__list {
                    display: block;

                    > #{$root}__slide {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                        opacity: 0;

                        &.is-active {
                            position: relative;
                            z-index: 1;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &__slider {
            position: relative;
        }

        &__track {
            position: relative;
            z-index: 0;
            overflow: hidden;
        }

        &__list {
            margin: 0 auto;
            padding: $space-25 0;
            will-change: transform;
            width: auto;
            height: auto;
        }

        &.is-active {
            #{$root}__list {
                display: flex;
            }
        }

        &__wrapper {
            position: relative;
            padding-bottom: calc($aspect-ratio-h / $aspect-ratio-w) * 100%;
            height: 0;
        }

        &__slide {
            position: relative;
            box-sizing: border-box;
            list-style-type: none;
            margin: 0;
            flex-shrink: 0;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                vertical-align: bottom;
            }
        }

        &__pagination {
            display: inline-flex;
            align-items: center;
            width: 95%;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;

            li {
                list-style-type: none;
                display: inline-block;
                line-height: 1;
                margin: 0;
            }
        }
    }

    &-slider {
        grid-column: 3 / 11;
        width: 100%;

        @include mq(medium) {
            display: none;
        }

        @include slider(&);

        &__slide {
            @media (hover: hover) {
                &:focus-visible {
                    @include brix-focus(true, $space-15);
                }
            }
        }

        &__pagination {
            position: relative;
            z-index: 1;
            margin-top: $space-100;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;

            &__page {
                display: inline-block;
                width: 8px;
                height: 8px;
                background-color: $color-monochrome-300;
                border-radius: $border-radius-half;
                margin: 0 calc(#{$space-75} / 2);
                padding: 0;
                transition: transform $duration-200 linear;
                box-sizing: content-box;
                vertical-align: middle;

                &.is-active {
                    border: $border-width-medium $border-style-solid $color-primary-100;
                    background: $color-primary-100;
                }

                &:hover {
                    cursor: pointer;
                    opacity: .9;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
}
