/** @define discovery-card */
@import '../styles/scss/precss/precss';

$discovery-card-class-prefix: $ws10-css-prefix + '-discovery-card';

.#{$discovery-card-class-prefix} {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $space-150;

    @include mq(large) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: $space-200;
    }

    &--full-width {
        grid-template-columns: 1fr;
        grid-gap: $space-150;
    }

    &__item {
        @include card-base;

        display: flex;
        flex-direction: column;
        width: 100%;
        flex-wrap: nowrap;
        margin-top: 0;

        &--vertical {
            grid-row-end: span 2;
        }

        @include mq(small) {
            &--horizontal {
                flex-direction: row-reverse;

                // The figma description says 280 pixels explicitly, there's no corresponding design token
                // eslint-disable-next-line
                min-height: 280px;
            }
        }

        &--left {
            .#{$discovery-card-class-prefix} {
                &__content-container {
                    align-items: start;
                    text-align: left;
                }

                &__promo-price {
                    .#{$ws10-css-prefix}-promo-price {
                        text-align: left;
                    }
                }
            }
        }

        &--center {
            .#{$discovery-card-class-prefix} {
                &__promo-price {
                    .#{$ws10-css-prefix}-promo-price {
                        text-align: center;
                    }
                }
            }
        }

        &--horizontal {
            .#{$discovery-card-class-prefix} {
                &__content-container {
                    align-items: center;
                    text-align: center;

                    @include mq(small) {
                        align-items: start;
                        text-align: left;
                        padding-right: $space-75;
                        justify-content: center;
                    }
                }

                &__content {
                    @include mq(small) {
                        margin: 0 0 $space-100;
                    }
                }

                &__image-container {
                    flex: 2;

                    @include mq(medium) {
                        padding-bottom: 0;
                    }
                }

                &__promo-price {
                    .#{$ws10-css-prefix}-promo-price {
                        text-align: center;

                        @include mq(small) {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: inherit;
    }

    &__action-button {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: inherit;

        &:not(:last-child) {
            margin-bottom: $space-75;

            @include mq(small) {
                margin-bottom: $space-100;
            }
        }
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        height: 100%;
        flex: 3;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: inherit;
        margin: $space-100 0;
    }

    &__txt-content {
        p {
            margin-bottom: 0;
        }

        ul,
        ol {
            display: inline-block;
            margin-top: $space-50;
            margin-bottom: 0;
        }

        margin-bottom: $space-75;
    }

    &__headline {
        margin-bottom: $space-75;
    }

    &__highlight-badge {
        display: flex;
        margin-bottom: $space-75;
    }

    &__picture {
        line-height: 0;
        width: 100%;
        flex-shrink: 0;
    }

    &__promo-price {
        display: flex;
    }

    &__image-container {
        display: flex;
        position: relative;

        // magic number to reach 16:9 ratio on every viewport
        padding-bottom: 56.25%;
        width: 100%;
    }

    &__image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        border-radius: $border-radius-medium;
        position: absolute;
        top: 0;
        left: 0;
    }
}
