/* stylelint-disable max-nesting-depth */

@import '../styles/scss/precss/precss';
@import '../flag-badge/flag-badge';

$option-picker-class-prefix: $ws10-css-prefix + '-option-picker';

.#{$option-picker-class-prefix} {
    @include ws10-text(false);

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    margin: 0 (-(calc($space-50 / 2))) (-$space-50);

    @include mq(small) {
        grid-template-columns: repeat(3, 1fr);
        margin: 0 (-(calc($space-75 / 2))) (-$space-75);
    }

    @include mq(large) {
        grid-template-columns: repeat(4, 1fr);
        margin: 0 (-(calc($space-100 / 2))) (-$space-100);
    }

    &[data-option-count='1'] {
        // one option
        grid-template-columns: repeat(1, 1fr);
    }

    &[data-option-count='2'],
    &[data-option-count='3'] {
        // few options
        grid-template-columns: repeat(2, 1fr);
    }

    &--with-flag-badge {
        padding-top: get-custom-property(flag-badge-height);
        grid-row-gap: calc(get-custom-property(flag-badge-height) + $space-50);
    }

    &__item {
        position: relative;
        z-index: 1;

        // prevents the item from growing
        min-width: 0;
        padding: 0 calc($space-50 / 2) $space-50;

        @include mq(small) {
            padding: 0 calc($space-75 / 2) $space-75;
        }

        @include mq(large) {
            padding: 0 calc($space-100 / 2) $space-100;
        }

        &--with-flag-badge {
            .#{$option-picker-class-prefix}__label {
                border-top-left-radius: 0;
            }
        }

        &--with-full-flag-badge {
            .#{$option-picker-class-prefix}__label {
                border-top-right-radius: 0;
            }
        }
    }

    &__input {
        &:checked {
            + .#{$option-picker-class-prefix} {
                &__label {
                    box-shadow: 0 0 0 $border-width-medium $color-secondary-100;

                    .#{$option-picker-class-prefix} {
                        &__text {
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }
        }

        &:focus-visible {
            + .#{$option-picker-class-prefix} {
                &__label {
                    // here we use $border-width-large instead of $border-width-medium so the focus is better visible
                    box-shadow: 0 0 0 $border-width-large $color-secondary-600;
                }
            }
        }

        &:disabled {
            + .#{$option-picker-class-prefix} {
                &__label {
                    background-color: $color-monochrome-200;
                    box-shadow: 0 0 0 $border-width-small $color-monochrome-300;
                    color: $color-monochrome-300;
                    cursor: not-allowed;

                    .#{$option-picker-class-prefix} {
                        &__icon-container {
                            svg {
                                color: $color-monochrome-300;
                            }
                        }

                        &__text {
                            font-weight: $font-weight-normal;
                        }
                    }
                }
            }
        }
    }

    &__label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: $space-100 $space-75;
        border-radius: $border-radius-medium;
        background-color: $color-monochrome-100;
        box-shadow: 0 0 0 $border-width-small $color-monochrome-400;
        text-align: center;
        cursor: pointer;
        color: $color-monochrome-600;

        @include mq(small) {
            padding-left: $space-100;
            padding-right: $space-100;
        }

        &:hover {
            box-shadow: 0 0 0 $border-width-small $color-monochrome-600;
        }
    }

    &__flag-badge {
        position: absolute;
        bottom: 100%;
        left: calc($space-50 / 2) - $border-width-small;
        z-index: -1;

        @include mq(small) {
            left: calc($space-75 / 2) - $border-width-small;
        }

        @include mq(large) {
            left: calc($space-100 / 2) - $border-width-small;
        }

        &--small {
            bottom: 100%;

            @include mq(medium) {
                bottom: 100%;
            }
        }

        &--full {
            right: calc($space-50 / 2);

            @include mq(small) {
                right: calc($space-75 / 2) - $border-width-small;
            }

            @include mq(large) {
                right: calc($space-100 / 2) - $border-width-small;
            }
        }
    }

    &__visual {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 0 $space-50;
    }

    &__color-swatch {
        width: $size-icon-200;
        height: $size-icon-200;
        border: $border-width-small solid $color-monochrome-300;
        border-radius: 50%;

        @include mq(small) {
            width: $size-icon-225;
            height: $size-icon-225;
        }
    }

    &__icon-container {
        svg {
            display: block;
            width: $size-icon-200;
            height: $size-icon-200;
            color: $color-monochrome-600;

            @include mq(small) {
                width: $size-icon-225;
                height: $size-icon-225;
            }
        }
    }

    &__image {
        display: block;
        width: 100%;
        max-width: 110px;
    }

    &__text {
        display: block;
        font-weight: $font-weight-normal;
        line-height: $line-height-fix-150;

        &--primary {
            font-size: $font-size-150;
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;

            @include mq(small) {
                font-size: $font-size-200;
            }
        }

        &--secondary {
            font-size: $font-size-100;
        }

        &--primary + &--secondary {
            margin-top: $space-25;
        }
    }

    &--text {
        .#{$option-picker-class-prefix} {
            &__label {
                @include mq(small) {
                    padding-top: $space-125;
                    padding-bottom: $space-125;
                }
            }
        }
    }

    &--image,
    &--color {
        .#{$option-picker-class-prefix} {
            &__input {
                &:disabled {
                    + .#{$option-picker-class-prefix} {
                        &__label {
                            .#{$option-picker-class-prefix} {
                                &__visual {
                                    opacity: .2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--image {
        .#{$option-picker-class-prefix} {
            &__visual {
                margin-bottom: $space-75;
            }
        }
    }
}
