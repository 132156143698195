@import '../styles/scss/precss/precss';

// See Design Tokens page in Brix Explorer for whats available (colors, fonts etc) or check in pre css
$auto-suggestion-class-prefix: 'ws10-auto-suggestion';
$auto-suggestion-item-class-prefix: 'ws10-auto-suggestion-item';

.#{$auto-suggestion-class-prefix} {
    @include ws10-text(false);

    &__section-header {
        position: relative;
        font-weight: $font-weight-bold;
        margin: $space-100 0 $space-50;
    }

    a {
        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:focus,
        &:focus-visible {
            outline: none;
        }

        &:focus:not(:focus-visible) {
            box-shadow: none;
        }
    }

    &__button-container {
        margin-top: $space-100;
    }
}

.#{$auto-suggestion-item-class-prefix} {
    @include ws10-text(false);

    list-style: none;

    &__wrapper {
        display: flex;
        width: 100%;
        padding: $space-50;
        align-items: center;
    }

    &__image {
        width: $size-icon-300;
        height: $size-icon-300;
        object-fit: contain;

        @include mq(small) {
            width: $size-icon-350;
            height: $size-icon-350;
        }
    }

    &__text-wrapper {
        margin-left: $space-75;
        flex-grow: 1;
    }

    &__text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__link {
        padding: $space-75 $space-75 $space-75 $space-0;

        &:focus {
            @include brix-focus;

            background-color: $color-monochrome-300;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $color-monochrome-300;
            }
        }
    }

    &__color-swatch-container {
        .#{$ws10-css-prefix}-color-swatch {
            justify-content: left;
            margin-bottom: $space-0;
            margin-top: $space-25;
        }
    }

    a {
        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:focus,
        &:focus-visible {
            outline: none;
        }

        &:focus:not(:focus-visible) {
            box-shadow: none;
        }
    }
}
