/** @define icon-strip */
@import './variables';
@import '../styles/scss/precss/precss';

$icon-strip-class-prefix: 'ws10-icon-strip';

.#{$icon-strip-class-prefix} {
    padding-block: $space-150;
    min-width: 100%;
    background: $color-gradient-100;
    display: flex;
    height: 100%;
    justify-content: center;

    &__container {
        @include mq(x-small) {
            width: $container-width-x-small;
        }

        @include mq(small) {
            width: $container-width-small;
        }

        @include mq(large) {
            width: $container-width-large;
        }
    }

    &__items-container {
        // Using !important due to unforeseeable order of CSS (ws10-grid could overwrite our margin)
        // stylelint-disable-next-line declaration-no-important
        margin-bottom: 0 !important;
        justify-content: center;
    }

    &__item {
        display: flex;
        flex-direction: column;

        @include ws10-text;

        justify-content: center;
        align-items: center;
        flex-grow: 1;
        text-align: center;
        margin-left: auto;
        margin-right: auto;

        // Using !important(s) due to higher specificity in older styles. Please ensure this is necessary before changing.
        // stylelint-disable-next-line declaration-no-important
        color: $color-monochrome-100 !important;
        margin-bottom: 0;

        &:hover {
            // Using !important(s) due to higher specificity in older styles. Please ensure this is necessary before changing.
            // stylelint-disable-next-line declaration-no-important
            text-decoration: none !important;
        }

        &:focus-visible {
            @include brix-focus(inside);
        }

        &:focus {
            outline: none;
        }

        width: $item-width-x-small;

        @include mq(small) {
            min-width: $item-width-small;
        }

        svg {
            height: $size-icon-350;
            margin-bottom: $space-50;
            overflow: visible;
            width: inherit;

            use {
                width: $size-icon-350;
                height: $size-icon-350;
            }

            @include mq(large) {
                animation: decrease-size $duration-200 $ease-in-out-sine forwards;
                transform-origin: top;
            }
        }

        &:hover svg {
            @include mq(large) {
                animation: increase-size $duration-200 $ease-in-out-sine forwards;
                transform-origin: top;
            }
        }
    }

    &__span {
        font-size: $font-size-100;
        font-weight: $font-weight-normal;
        line-height: $line-height-50;

        @include mq(small) {
            font-size: $font-size-150;
        }
    }

    @keyframes increase-size {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.021);
        }

        100% {
            transform: scale(1.142);
        }
    }

    @keyframes decrease-size {
        0% {
            transform: scale(1.142);
        }

        50% {
            transform: scale(1.021);
        }

        100% {
            transform: scale(1);
        }
    }
}
