// import only precss from styles package! This contains all your variables you need!
@import '../styles/scss/precss/precss';

$element: $ws10-css-prefix + '-form-text-input';
$input: $element + '__input';

@include form-element-border($element, $input);
@include form-element-icons($element, $input);
@include form-element-height($element, $input);

/**
 * Edgecase
 * If a form-element has a notification icon and a system icon
 * then we don't to overlap the value of the form element and the notification icon
 */
.#{$element}--has-system-icon.#{$element}--error,
.#{$element}--has-system-icon.#{$element}--success {
    .#{$input} {
        padding-right: $space-50 + $size-icon-150 + $space-50 + $size-icon-150 + $space-100;

        @include mq(small) {
            padding-right: $space-50 + $size-icon-150 + $space-50 + $size-icon-200 + $space-100;
        }
    }
}
