@import '../styles/scss/precss/precss';
@import '../system-icon/system-icon';

$class-prefix: $ws10-css-prefix + '-filter-pill';
$filter-pill-shadow-border-small: inset 0 0 0 $border-width-small $color-monochrome-600;
$filter-pill-shadow-border-disabled: inset 0 0 0 $border-width-small $color-monochrome-300;
$filter-pill-shadow-border-medium: inset 0 0 0 $border-width-medium $color-monochrome-600;

.#{$class-prefix} {
    @include ws10-text(false);

    &--center {
        .#{$class-prefix} {
            &__headline {
                text-align: center;
            }

            &__items {
                justify-content: center;
            }
        }
    }

    &--right {
        .#{$class-prefix} {
            &__headline {
                text-align: right;
            }

            &__items {
                justify-content: right;
            }
        }
    }

    &__headline {
        display: block;
        width: 100%;

        > * {
            // important is needed to overwrite the alignment of the headline itself
            // stylelint-disable-next-line declaration-no-important
            text-align: inherit !important;
        }
    }

    &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: $color-monochrome-600;
        gap: $space-100 $space-75;

        &--open {
            .#{$class-prefix}__toggle-item {
                &:has(> .#{$class-prefix}__toggle--less) {
                    display: block;
                }

                &:has(> .#{$class-prefix}__toggle--more) {
                    display: none;
                }
            }
        }
    }

    &__item-input {
        &:checked,
        &:checked:disabled {
            + .#{$class-prefix} {
                &__item-label {
                    box-shadow: none;
                    background-color: $color-secondary-100;
                    color: $color-monochrome-100;

                    &:hover {
                        box-shadow: none;
                        background-color: hover($color-secondary-100);
                    }

                    .#{$class-prefix}__item {
                        &-text,
                        &-icon {
                            color: $color-monochrome-100;
                        }
                    }
                }
            }
        }

        &:checked:disabled {
            + .#{$class-prefix} {
                &__item-label {
                    background-color: hover($color-secondary-100);
                }
            }
        }

        &:disabled {
            + .#{$class-prefix} {
                &__item-label {
                    box-shadow: $filter-pill-shadow-border-disabled;
                    color: $color-monochrome-300;
                    pointer-events: none;

                    .#{$class-prefix}__item {
                        &-text,
                        &-icon {
                            color: $color-monochrome-300;
                        }
                    }
                }
            }
        }

        &:focus-visible {
            + .#{$class-prefix} {
                &__item-label {
                    @include brix-focus;
                }
            }
        }
    }

    &__item {
        &-icon {
            $icon-color: currentcolor;

            // the display: block is needed to overwrite simplicity which globally hides all svgs inside labels
            display: block;
            color: $icon-color;
            margin-right: $space-25;
        }

        &-label {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: $space-50 $space-75;
            box-shadow: $filter-pill-shadow-border-small;
            border-radius: $border-radius-pill;
            background-color: $color-monochrome-100;
            user-select: none;

            &:hover {
                box-shadow: $filter-pill-shadow-border-medium;
            }
        }

        &-text {
            white-space: nowrap;
        }
    }

    &__toggle-item {
        display: none;

        &:has(> .#{$class-prefix}__toggle--more) {
            display: block;
        }
    }

    &__toggle {
        background-color: transparent;
        // stylelint-disable-next-line declaration-no-important
        margin-bottom: 0 !important;
        padding: $space-50;
        text-decoration: $text-decoration-underline;

        &:hover {
            cursor: pointer;
        }

        &:focus-visible {
            @include brix-focus;
        }
    }
}
