/**
 * @tokens Viewports
 */

$breakpoint-x-small: 320px;
$breakpoint-small: 600px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;
$breakpoint-x-large: 1280px;

/**
 * @tokens-end
 */

$breakpoints: (
    'x-small': $breakpoint-x-small,
    'small': $breakpoint-small,
    'medium': $breakpoint-medium,
    'large': $breakpoint-large,
    'x-large': $breakpoint-x-large
);
