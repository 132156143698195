@import '../styles/scss/precss/precss';

$info-overlay-class-prefix: 'ws10-info-overlay';

@mixin flex-mixin($direction,$content) {
    display: flex;
    flex-direction: $direction;
    justify-content: $content;
}

@mixin icon-mixin($margin-bottom,$width,$height,$fill,$stroke) {
    display: inline-block;
    margin-bottom: $margin-bottom;
    align-self: center;
    width: $width;
    height: $height;
    border-radius: 50%;
    color: $stroke;
}
.#{$info-overlay-class-prefix} {
    @include ws10-text(false);

    display: flex;
    border: $border-none;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-medium;
    padding: $space-100;
    background-color: $color-monochrome-100;
    margin-bottom: $space-100;

    &__wrapper {
        display: inline-block;
        width: 100%;
    }

    @include mq(large) {
        width: 896px;
        padding: $space-125;
    }

    @include mq(medium) {
        width: 736px;
        padding: $space-125;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--info {
            @include flex-mixin(column,center);

            align-items: center;
            position: relative;
        }

        &--title {
            margin-bottom: $space-25;
            font-weight: $font-weight-bold;
            font-size: $font-size-200;
            line-height: $space-150;
            color: $color-monochrome-500;
            margin-top: $space-200;
            text-align: center;

            @include mq(large) {
                margin-top: $space-150;
            }

            @include mq(medium) {
                margin-top: $space-150;
            }
        }

        &--subTitle {
            margin-bottom: $space-100;
            font-weight: $font-weight-normal;
            font-size: $font-size-150;
            text-align: center;
        }

        &--icon {
            position: absolute;
            top: -54%;

            img {
                width: $size-icon-525;
                height: $size-icon-525;
            }

            @media (min-width: ($breakpoint-x-small)) and (max-width: (400px)) {
                top: -35%;
            }

            @include mq(large) {
                top: -69%;
            }

            @include mq(medium) {
                top: -69%;
            }
        }

        &--hr {
            width: 100%;
            height: 1px;
            margin-top: $space-0;
            padding: $space-0;
            border: calc($border-width-small * 1 / 3) $border-style-solid $color-monochrome-300;
        }
    }

    &__subContainer {
        @include flex-mixin(column,flex-start);

        margin-left: $space-100;
        padding-right: $space-75;

        svg {
            align-self: center;
        }

        @include mq(large) {
            flex-direction: row;
        }

        @include mq(medium) {
            flex-direction: row;
        }

        &-icon {
            @include icon-mixin($space-50,$size-icon-150,$size-icon-150,$color-monochrome-600,$color-monochrome-600);

            @include mq(large) {
                margin-right: $space-25;
            }

            @include mq(medium) {
                margin-right: $space-25;
            }
        }

        &-text {
            margin-bottom: $space-25;

            @include mq(large) {
                margin-right: $space-15;
            }

            @include mq(medium) {
                margin-right: $space-15;
            }
        }
    }

    &__sub-info {
        @include flex-mixin(row,center);

        margin: $space-100;
        margin-bottom: $space-0;
        div.#{$ws10-css-prefix}-info-overlay__subContainer {
            text-align: center;
            border-right: 1px $border-style-solid $color-monochrome-300;
            margin-left: $space-75;
        }
        div.#{$ws10-css-prefix}-info-overlay__subContainer:last-child {
            border: $border-none;
        }
    }

    .notification-tick {
        @include icon-mixin($space-100,$size-icon-450,$size-icon-450,$color-secondary-500,$color-secondary-500);
    }

    .notification-info {
        @include icon-mixin($space-100,$size-icon-450,$size-icon-450,$color-secondary-600,$color-secondary-600);
    }

    .notification-error {
        @include icon-mixin($space-100,$size-icon-450,$size-icon-450,$color-primary-200,$color-primary-200);
    }

    &__infoText {
        width: 100%;
    }
}
