@use 'sass:map';
@import '../styles/scss/precss/precss';
@import '../flag-badge/flag-badge-variables';

$promotional-card-class-prefix: $ws10-css-prefix + '-promotional-card';
$form-selection-control-class: $ws10-css-prefix + '-form-selection-control';

.#{$promotional-card-class-prefix} {
    &--standalone {
        .#{$promotional-card-class-prefix} {
            &__card {
                background-color: $color-monochrome-100;
                box-shadow: $shadow-medium;
            }
        }
    }

    &--nested {
        .#{$promotional-card-class-prefix} {
            &__card {
                background-color: $color-monochrome-200;
            }
        }
    }

    &__card {
        display: flex;
        color: $color-monochrome-600;
        border-radius: $border-radius-medium;
        padding: $space-100;

        @include mq(small) {
            padding: $space-125;
        }

        // border color is set below using the :has selector
        border: 1px solid transparent;

        @include mq(small) {
            padding: $space-125;
        }

        .#{$promotional-card-class-prefix}__flag-badge + & {
            border-radius: 0 $border-radius-medium $border-radius-medium $border-radius-medium;
        }

        .#{$promotional-card-class-prefix}__flag-badge:has(> .ws10-flag-badge--green) + & {
            border-color: $color-secondary-500;
        }

        @each $color, $styles in $flag-badge-colors {
            .#{$promotional-card-class-prefix}__flag-badge:has(.#{$flag-badge-class-prefix}--#{$color}) + & {
                border-color: map.get($styles, 'background');
            }

            .#{$promotional-card-class-prefix}__flag-badge:has(.#{$flag-badge-class-prefix}--#{$color}.#{$flag-badge-class-prefix}--inverse) + & {
                border-color: map.get($styles, 'inverse', 'background');
            }
        }

        .#{$form-selection-control-class} {
            margin-top: $space-25;

            // override formSelectionControl's css to make label bold, and remove p tag margin
            &__text > * {
                font-weight: $font-weight-bold;
                margin-bottom: 0;
            }
        }
    }

    &__icon {
        margin-right: $space-75;
        color: $color-monochrome-600;
        flex-shrink: 0;

        @include mq(small) {
            margin-right: $space-100;
        }
    }

    &__sublabel > p {
        margin-bottom: $space-0;
    }

    &__confirmation-text {
        display: block;
        font-size: 0;
        opacity: 0;
        margin-top: 0;

        /* fade out, then shrink */
        transition: opacity $duration-100, font-size $duration-200 $duration-100, margin $duration-100 $duration-100;

        &--show {
            @include ws10-text;

            margin-bottom: 0;
            opacity: 1;
            margin-top: $space-25;

            /* unshrink, then fade in */
            transition: font-size $duration-200, margin $duration-100, opacity $duration-100 $duration-200;
        }
    }
}
