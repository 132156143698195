/** @define table */

@import '../styles/scss/precss/precss';

$table-class-prefix: $ws10-css-prefix + '-table';

.#{$table-class-prefix} {
    &__table {
        width: 100%;
        border-collapse: collapse;
    }

    &__row:not(:last-child) {
        border-bottom: 1px solid $color-monochrome-300;
    }

    &__cell {
        @include ws10-text(false);

        padding: $space-75 $space-50;
        color: $color-monochrome-600;

        &--header {
            line-height: $line-height-100;
            font-size: $font-size-150;
            font-weight: $font-weight-bold;

            // follows ws10-grid column-5 calculation but keeps table display (a11y)
            width: calc(100% / (12 / 5));
        }

        &--header ~ & {
            @include mq(small) {
                padding-left: $space-100;
            }

            @include mq(large) {
                padding-left: $space-200;
            }
        }
    }

    @include mq(mobile-only) {
        table,
        tbody,
        tr,
        th,
        td {
            display: block;
        }

        &__row {
            padding: $space-75 0;
        }

        &__cell {
            padding: 0 $space-50 $space-50;

            &:last-child {
                padding-bottom: 0;
            }

            &--header {
                width: 100%;
                line-height: $line-height-100;
                font-size: $font-size-100;
            }
        }
    }
}
