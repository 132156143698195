/** @define icon-text-banner */
@import '../styles/scss/precss/precss';

$icon-text-banner-class-prefix: 'ws10-icon-text-banner';

.#{$icon-text-banner-class-prefix} {
    @mixin icon-color($bgColor) {
        @if $bgColor == 'white' {
            color: $color-monochrome-100;
        }
        @else {
            color: $color-monochrome-600;
        }
    }

    @include ws10-text(false);

    &__buttons-container {
        flex-direction: row;
        display: flex;
        justify-content: center;
        padding-top: $space-200;

        @include mq(mobile-only) {
            flex-direction: column;
        }
    }

    &__headline {
        font-weight: $font-weight-bold;
        margin-bottom: $space-75;
    }

    .ws10-belt-container {
        padding: $space-200 0 $space-200 0;

        @include mq(large) {
            padding: $space-300 0 $space-300 0;
        }

        @include mq(medium) {
            padding: $space-300 0 $space-300 0;
        }
    }

    &__headlines-container-centered {
        display: grid;
        align-items: center;
        justify-content: center;

        .ws10-icon-text-banner__headline {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .ws10-icon-text-banner__sub-headline {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    &__sub-headline {
        @include ws10-text(false);
    }

    &__icon-text-container {
        padding-top: $space-200;
    }

    &--darkRed {
        background-color: $color-primary-300;

        .ws10-icon-text-banner__sub-headline,
        h2 {
            color: $color-monochrome-600-inverse;
        }

        .ws10-icon__svg {
            @include icon-color('white');
        }
    }

    &--redGradiant {
        background: $color-gradient-100;

        .ws10-icon-text-banner__sub-headline,
        h2 {
            color: $color-monochrome-600-inverse;
        }

        .ws10-icon__svg {
            @include icon-color('white');
        }
    }

    &--lightGrey {
        background-color: $color-monochrome-200;

        .ws10-icon__svg {
            @include icon-color('black');
        }
    }

    &--white {
        background-color: $color-monochrome-100;
    }

    &__buttons {
        @include mq(mobile-only) {
            margin-bottom: $space-125;
            margin-right: $zero;

            &:last-child {
                margin-bottom: $zero;
            }
        }

        margin-right: $space-150;
    }

    .ws10-icon--medium.ws10-icon--system {
        width: $size-icon-300;
        height: $size-icon-300;
    }
}
