/** @define carousel */
@import '../styles/scss/precss/precss';
@import '@splidejs/splide/dist/css/splide.min.css';

$carousel-class-prefix: $ws10-css-prefix + '-carousel';
$tariff-card-class: 'ws10-tariff-card';

.#{$carousel-class-prefix} {
    position: relative;
    margin: $zero;

    &__hide-Arrow-slide {
        transform: none !important;
    }

    .ws10-grid__inner {
        margin-top: $zero;
        display: flex !important;
        flex-wrap: nowrap !important;

        @include mq('large') {
            margin: $zero;
            width: $full-percent;
        }
    }

    &-slider {
        margin: $zero;
        outline: none;

        * {
            box-sizing: border-box;

            &:focus,
            &:hover {
                outline: none;
            }
        }

        &__list {
            display: flex;
            flex-wrap: nowrap;
            width: $full-percent !important;
        }

        &__track {
            @include mq(mobile-only) {
                padding-right: $space-15 !important;
                padding-left: $space-15 !important;
            }

            overflow: hidden;
        }

        &__slide {
            padding-bottom: $space-75;
            position: relative;
            overflow-x: hidden;
            flex-basis: auto !important;
        }

        &__arrows {
            min-width: $full-percent;
            display: flex;
            position: absolute;
            justify-content: space-between;
            top: calc(#{$full-percent} - #{$space-300});
        }

        &__arrow {
            &--prev {
                margin: $zero !important;
                display: block;

                @include mq(mobile-only) {
                    left: $zero !important;
                }

                @media (max-width: $breakpoint-large) {
                    left: $zero !important;
                }
            }

            &--next {
                position: absolute;
                left: calc($full-percent - (#{$space-400})) !important;

                @include mq(mobile-only) {
                    left: calc($full-percent - (#{$space-200}) + ( #{ - $space-75})) !important;
                }

                @include mq('medium') {
                    left: calc($full-percent - (#{$space-300}) + (#{$space-25})) !important;
                }

                @include mq('large') {
                    left: calc($full-percent - (#{$space-400})) !important;
                }
            }
        }

        &__pagination {
            position: relative;
            top: $full-percent;
            left: $half-percent;
            transform: translateX(-$half-percent);
            width: $full-percent;
            height: $space-300;
            padding-top: $zero;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0;
            pointer-events: none;

            li {
                display: inline-block;
                line-height: 1;
                list-style-type: none;
                margin: 0;
                pointer-events: none;
            }

            &__page {
                background-color: $color-monochrome-400;
                border-radius: $border-radius-half;
                margin: 0 calc(#{$space-75} / 2);
                padding: 0;
                transition: transform $duration-200 linear;
                box-sizing: content-box;
                display: inline-block;
                width: $space-50;
                height: $space-50;
                position: relative;

                &.is-active {
                    /** overwrite splide active style */
                    transform: scale(1) !important;
                    border: $border-width-medium $border-style-solid $color-primary-100;
                    background: $color-primary-100 !important;
                }

                &:hover {
                    cursor: pointer;
                    opacity: 90%;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        &__pagination__page--inverse {
            // stylelint-disable-next-line
            @extend .ws10-carousel-slider__pagination__page;

            background-color: $color-monochrome-400-inverse !important;

            &.is-active {
                border: $border-width-medium $border-style-solid $color-primary-100-inverse !important;
                background: $color-primary-100-inverse !important;
            }
        }
    }
    .#{$tariff-card-class} {
        > div:first-child {
            width: 100%;
            height: 100%;
        }
    }
}

.splide__arrow {
    transform: none !important;
    background-color: transparent !important;
    width: auto !important;
    height: auto !important;
    opacity: 1 !important;

    svg {
        width: $space-200 !important;
        height: $space-200 !important;

        @include mq(mobile-only) {
            width: $space-150 !important;
            height: $space-150 !important;
        }
    }
}

.splide__arrow--next {
    right: $zero !important;
}

.splide__arrow--prev svg {
    transform: scaleX(1) !important;
}
