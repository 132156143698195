html {
    $design-tokens: (
        'color-primary': $colors-primary,
        'color-secondary': $colors-secondary,
        'color-monochrome': $colors-monochrome,
        'color-gradient': $colors-gradient,
        'color-primary-inverse': $colors-primary-inverse,
        'color-secondary-inverse': $colors-secondary-inverse,
        'color-monochrome-inverse': $colors-monochrome-inverse,
        'color-gradient-inverse': $colors-gradient-inverse,
        'size-icon': $sizes-icon,
        'size-button': $sizes-button,
        'space': $spaces,
        'breakpoint': $breakpoints,
        'font-size': $font-sizes,
        'line-height': $line-heights,
        'line-height-fix': $line-heights-fix,
        'font-weight': $font-weights,
        'text-decoration': $text-decorations,
        'opacity': $opacities,
        'border-style': $border-styles,
        'border-width': $border-widths,
        'border-radius': $border-radii,
        'shadow': $shadows,
        'duration': $durations,
        'easing': $easings,
    );

    @mixin set-custom-properties($name, $map) {
        @each $key, $val in $map {
            @include set-custom-property('#{$name}-#{$key}', $val);
        }
    }

    @each $name, $map in $design-tokens {
        @include set-custom-properties($name, $map);
    }
}
