@import '../styles/scss/precss/precss';

$container-prefix: 'ws10-number-badge';

.#{$container-prefix} {
    display: inline-block;
    position: relative;

    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: $color-primary-200;
        color: $color-monochrome-100;
        font-size: $font-size-25;
        font-weight: $font-weight-bold;
        min-width: $size-icon-100;
        height: $size-icon-100;
        border-radius: $border-radius-pill;
        top: -$space-25;

        &--size-200 {
            left: $space-125;

            &--padded {
                padding: $space-0 $space-25;
                transform: translateX(-26%);
            }
        }

        &--size-225 {
            left: $space-150;

            &--padded {
                padding: $space-0 $space-25;
                transform: translateX(-20%);
            }
        }

        @include mq(medium) {
            min-width: $size-icon-125;
            height: $size-icon-125;
            top: -$space-50;

            &--size-200 {
                &--padded {
                    padding: $space-0 $space-50;
                    transform: translateX(-26%);
                }
            }

            &--size-225 {
                &--padded {
                    padding: $space-0 $space-50;
                    transform: translateX(-20%);
                }
            }
        }
    }
}
