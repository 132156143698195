@import '../styles/scss/precss/precss';

$unordered-vertical-list-class-prefix: $ws10-css-prefix + '-unordered-vertical-list';

.#{$unordered-vertical-list-class-prefix} {
    @include ws10-text(false);

    background-color: $color-monochrome-600-inverse;

    &--inverse {
        color: $color-monochrome-600-inverse;
        background-color: $color-monochrome-500;
    }

    &__item {
        display: grid;
        grid-template-columns: auto 1fr;
        color: $color-monochrome-600;

        &--inverse {
            color: $color-monochrome-600-inverse;
            background-color: $color-monochrome-500;
        }

        // spacing between items
        & + & {
            margin-top: $space-100;
        }
    }

    &__headline,
    &__text,
    &__link {
        grid-column: 2 / 3;
        align-self: center;
        justify-self: start;
    }

    &__text {
        p {
            color: inherit;
        }
    }

    &__headline__text {
        vertical-align: middle;

        &--default {
            @include ws10-h4;

            &.headline--inverse {
                @include ws10-h4-inverse;
            }
        }
    }

    &__icon {
        &--list {
            grid-column: 1 / 2;
            justify-self: end;
            margin-right: $space-50;
        }

        width: $size-icon-200;
        height: $size-icon-200;

        &--default {
            height: $size-icon-150;
            width: $size-icon-150;

            @include mq(small) {
                width: $size-icon-200;
                height: $size-icon-200;
            }
        }
    }

    &__tooltipIcon {
        &--list {
            grid-column: 1 / 2;
            align-self: center;
            justify-self: end;
            margin-right: $space-50;
        }

        height: $size-icon-150;
        width: $size-icon-150;
    }

    &__link {
        &:focus-visible {
            @include brix-focus(false);
        }
    }

    &__tooltip-trigger {
        display: inline-block;
        margin-left: $space-15;
        line-height: 0;
        vertical-align: middle;
        position: relative;
        bottom: $space-15;

        &--inverse {
            color: $color-monochrome-600-inverse;
        }
    }

    .#{$ws10-css-prefix}-text-link {
        @include ws10-text-link($color-monochrome-600, $color-primary-200, $color-monochrome-300);
    }

    p {
        margin-bottom: 0;
    }
}
