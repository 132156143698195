/* stylelint-disable selector-max-id */
@use 'sass:map';
@import '../styles/scss/precss/precss';
@import '../button/button';
@import '../button-icon-only/button-icon-only';
@import '../system-icon/system-icon';

$fullwidth-notification-class-prefix: $ws10-css-prefix + '-fullwidth-notification';
$fullwidth-notification-wrapper-class-prefix: $fullwidth-notification-class-prefix;
$fullwidth-notification-content-class-prefix: $fullwidth-notification-class-prefix + '__content';
$fullwidth-notification-with-icon-prefix: $fullwidth-notification-class-prefix + '--with-icon';
$colors: primary, secondary, tertiary;
$fullwidth-notification-variations: (
    primary: (
        background-color: $color-monochrome-500,
        text-color: $color-monochrome-100,
        button-color: primary,
    ),
    secondary: (
        background-color: $color-primary-100,
        text-color: $color-monochrome-100,
        button-color: secondary-inverse,
    ),
    tertiary: (
        background-color: $color-secondary-100,
        text-color: $color-monochrome-100,
        button-color: secondary-inverse,
    ),
);

// simplicity element
#top {
    &.transition-top {
        transition: margin-top $duration-500 $ease-in-out-sine, margin-bottom $duration-500 $ease-in-out-sine;
    }
}

// simplicity element
.main-nav {
    &.transition-top {
        transition: top $duration-500 $ease-in-out-sine;
    }
}

.#{$fullwidth-notification-wrapper-class-prefix} {
    overflow: hidden;
    position: relative;
    left: 0;
    right: 0;
    opacity: 1;
    transition: height $duration-500 $ease-in-out-sine;

    &--sticky-top,
    &--sticky-bottom {
        z-index: 999;
        position: fixed;
    }

    &--sticky-top {
        top: 0;
    }

    &--sticky-bottom {
        bottom: 0;
    }

    &--below-navbar {
        &.transition-top {
            transition: top $duration-500 $ease-in-out-sine;
        }
    }

    @each $color in $colors {
        $variant: map.get($fullwidth-notification-variations, $color);
        $variant-background-color: map.get($variant, background-color);
        $variant-text-color: map.get($variant, text-color);
        $variant-button-color: map.get($variant, button-color);

        & {
            &.#{$fullwidth-notification-with-icon-prefix} {
                grid-template-columns: 1fr 9fr;
            }

            &__inner {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                padding: $space-75 $space-50 $space-75 $space-125;

                @include mq(small) {
                    align-items: center;
                }

                @include mq(large) {
                    padding: $space-100 $space-50 $space-100 $space-150;
                }
            }

            &--#{$color} {
                background-color: $variant-background-color;
            }

            &__content {
                flex-grow: 1;
                display: grid;
                grid-template-columns: min-content 1fr;
                justify-items: start;
                gap: $space-50;
                margin-right: $space-125;

                @include mq(large) {
                    margin-right: $space-150;
                }

                @include mq(small) {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                }

                svg {
                    grid-row: 1/4;
                }

                grid-template-areas: 'icon content close-btn' '. button .' '. subtext .';
            }

            &__icon {
                grid-area: icon;
                width: $size-icon-125;
                height: $size-icon-125;

                @include mq(small) {
                    min-width: $size-icon-150;
                    min-height: $size-icon-150;
                }
            }

            &__text {
                grid-area: content;

                p {
                    @include ws10-text;

                    color: $variant-text-color;
                    margin-bottom: 0;
                }
            }

            &__button {
                grid-area: button;
                background-color: $variant-button-color;
                flex-shrink: 0;
            }

            &__subtext {
                grid-area: subtext;

                p {
                    @include ws10-text-smaller;

                    color: $variant-text-color;
                }
            }

            &__close {
                button {
                    grid-area: close-btn;

                    &:hover,
                    &:focus {
                        background-color: hover($color-monochrome-600-inverse);

                        svg * {
                            color: $color-monochrome-500;
                        }
                    }

                    &:active {
                        background-color: press($color-monochrome-600-inverse);

                        svg * {
                            color: $color-monochrome-500;
                        }
                    }

                    svg * {
                        color: $color-monochrome-100;
                    }
                }
            }
        }
    }
}
