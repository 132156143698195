/* ==========================================================================
   #RESET
   ========================================================================== */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
button,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
search,
section,
summary,
time,
mark,
audio,
video {
    @include reset-element;
}

html {
    line-height: 1;
    text-size-adjust: 100%;
}

ol,
ul {
    @include reset-list;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
}

q,
blockquote {
    quotes: none;
}

q::before,
q::after,
blockquote::before,
blockquote::after {
    content: '';
    content: none;
}

a img {
    border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
search,
section,
summary {
    display: block;
}
