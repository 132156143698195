@import '../styles/scss/precss/precss';

$color-swatch-class-prefix: $ws10-css-prefix + '-color-swatch';

.#{$color-swatch-class-prefix} {
    @include ws10-text(false);

    $margin-left: calc($space-25 / 2);
    $margin-right: $margin-left;
    $margin-bottom: $space-25;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 (-$margin-right) (-$margin-bottom) (-$margin-left);

    &__item {
        margin: 0 $margin-right $margin-bottom $margin-left;
    }

    &__color {
        $diameter: 12px;

        display: block;
        width: $diameter;
        height: $diameter;
        border: $border-width-small $border-style-solid $color-monochrome-400;
        border-radius: 50%;

        > rect {
            width: 100%;
            height: 100%;
        }
    }
}
