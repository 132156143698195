/* ==========================================================================
   #ELEMENTS
   Bare HTML elements
   ========================================================================== */

/* Body
========================================================================== */

@import '../precss/precss';

html {
    font-size: $font-size-50;
}

$body-font: $font-vodafone;
$body-color: $color-monochrome-600;
$body-bg: $color-monochrome-100;

body {
    font-family: $body-font;
    line-height: $line-height-reset;
    background: $body-bg;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Headings - taken from OLD simplicity to make elements look like
   they currently do. Supports old font sizes. For new system look at _text.scss
   DONT USE THIS IN NEW FEATURES
   ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $inherit;
}

/* Paragraphs
   ========================================================================== */

p {
    margin-bottom: $space-100;
}

/* Text decoration
   ========================================================================== */

strong,
b {
    font-weight: $font-weight-bold;
}

em {
    font-style: italic;
}

sup {
    vertical-align: super;
    font-weight: $font-weight-bold;
    font-size: $font-size-50;
    line-height: $line-height-reset;
    text-decoration: $text-decoration-underline;
    cursor: pointer;

    &:hover {
        text-decoration: $text-decoration-none;
    }
}

sub {
    $sub-font-size: 70%;

    font-size: $sub-font-size;
    position: relative;
    top: .2em;
}

/* Anchor
   ========================================================================== */

a {
    text-decoration: $text-decoration-none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:focus-visible {
        @include focus-outline;
    }
}

/* Input
   ========================================================================== */

input {
    // reset damn global input styles from simplicity (core.css)
    max-width: none;
    box-shadow: none;
}

/* SVG
   ========================================================================== */

svg {
    // with this rule we unset simplicity's fixed color (#333) which is applied globally to all SVGs
    // and which produces problems with color inheritance
    fill: unset;
}

/* Misc
   ========================================================================== */

.glnav a {
    // This is a temporary solution to provide the correct focus-outline for the old simplicity-navigation
    // until the new WS2 basis template will be launched.
    // We added this here, since WS2 already has its focus-styles, but in WS10 (this file in line 89 above),
    // all outlines are removed which also removes the outline of the old simplicity navigation which is bad.
    // So as soon as we use WS10 on a page, the simplicity navigation would lose its focus outlines.
    // Complain to Jonas or Timo!
    &:focus-visible {
        box-shadow: none;
        outline: $anchor-outline;
    }
}
