@import '../styles/scss/precss/precss';

$notification-class-prefix: 'ws10-notification';

@mixin arrow-mixin($isArrowBottom, $variation) {
    display: none;
    width: $zero;
    height: $zero;
    position: absolute;
    border-left: $border-width-xx-large $border-style-solid transparent;
    border-right: $border-width-xx-large $border-style-solid transparent;

    @if $isArrowBottom {
        border-top: $space-50 $border-style-solid $variation;
        bottom: -($space-50 - 1px);
    }
    @else {
        border-top: $zero;
        border-bottom: $space-50 $border-style-solid $variation;
        top: -($space-50 - 1px);
    }
}

$notification-variations: (
    error: $color-primary-200,
    success: $color-secondary-500,
    warn: $color-secondary-300,
    info: $color-secondary-600,
);

.#{$notification-class-prefix} {
    @include ws10-text(false);

    display: flex;
    margin-bottom: $space-150;
    border-radius: $border-radius-medium;

    &:last-child {
        margin-bottom: 0;
    }

    @each $name, $variation in $notification-variations {
        &--#{$name} {
            .#{$notification-class-prefix} {
                &__sidebar {
                    background-color: $variation;
                }

                &__content {
                    border: $border-width-medium $border-style-solid $variation;
                }

                &--arrow-bottom {
                    @include arrow-mixin(true, $variation);
                }

                &--arrow-top {
                    @include arrow-mixin(false, $variation);
                }
            }
        }
    }

    .#{$notification-class-prefix}-icon {
        color: $color-monochrome-100;
    }

    &,
    &--white {
        background-color: $color-monochrome-100;
    }

    &--transparent {
        background-color: transparent;
    }

    &__sidebar {
        position: relative;
        display: flex;
        justify-content: center;
        padding: $space-100 $space-50 $zero;
        border-top-left-radius: $border-radius-medium;
        border-bottom-left-radius: $border-radius-medium;

        &:first-child {
            svg {
                width: $size-icon-125;
                height: $size-icon-125;

                @include mq(small) {
                    width: $size-icon-150;
                    height: $size-icon-150;
                }
            }
        }

        @include mq(small) {
            padding: $space-150 $space-75 $zero;
        }
    }

    &__content {
        flex: auto;
        padding: $space-100;
        border-top-right-radius: $border-radius-medium;
        border-bottom-right-radius: $border-radius-medium;
        // stylelint-disable-next-line declaration-no-important
        border-left: 0 !important;

        @include mq(small) {
            padding: $space-150 $space-100;
        }
    }

    &__headline {
        padding-bottom: $space-50;
    }

    &__text {
        font-size: $font-size-100;

        @include mq(small) {
            font-size: $font-size-150;
        }
    }

    &__btns {
        display: flex;
        flex-direction: column;
        padding-top: $space-100;

        @include mq(small) {
            flex-direction: row;
        }

        span:nth-child(2) {
            margin-top: $space-75;

            @include mq(small) {
                margin-left: $space-100;
                margin-top: $zero;
            }
        }
    }

    &--small {
        margin-bottom: $zero;
        .#{$notification-class-prefix} {
            &__sidebar {
                display: flex;
                align-items: center;
                padding: $space-50;
            }

            &__content {
                padding: $space-50;
            }

            &__text {
                padding: $zero;
            }

            &--arrow-top,
            &--arrow-bottom {
                display: block;
            }
        }
    }
}
