@import '../styles/scss/precss/precss';
@import '../system-icon/system-icon';

$element: $ws10-css-prefix + '-form-select';
$select: $element + '__select';

@include form-element-border($element, $select);
@include form-element-icons($element, $select);
@include form-element-height($element, $select);

.#{$element} {
    @include ws10-text(false);

    position: relative;

    /**
     * Added that here in not in the form-element-icons, because in form-select we want a "click-through" the chevron-down
     * But in form-text-input there are icons (e.g. calendar) that might get event listener attached to them
     */
    .#{$ws10-css-prefix}-system-icon {
        pointer-events: none;
    }
}

.#{$select} {
    // simplicity (core.css) sets z-index: 1 to all select elements, so we need to reset that
    z-index: 0;
}

.dev-quad {
    position: absolute;
    width: 56px;
    height: 56px;

    @include mq(mobile-only) {
        width: 48px;
        height: 48px;
    }

    background: $color-primary-100;
}

.dev-quad.dev-quad-one {
    top: 0;
    left: 100px;
}

.dev-quad.dev-quad-two {
    top: 0;
    left: 100px;
}

.dev-quad.dev-quad-three {
    top: 0;
    left: 100px;
}
