/** @define linear-stepper */

@import '../styles/scss/precss/precss';

$linear-stepper-class: $ws10-css-prefix + '-linear-stepper';

.#{$linear-stepper-class} {
    $full-width: 100%;

    @include ws10-text(false);

    width: $full-width;

    &:focus-visible {
        @include brix-focus(false);
    }

    &__steps {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: $full-width;
    }

    &--center &__centered {
        @media screen and (min-width: $breakpoint-medium) {
            margin: auto;
        }
    }

    &__step {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        padding: $space-25 $space-0;
        min-width: $space-400;

        @media screen and (min-width: $breakpoint-medium) {
            min-width: auto;
        }

        &::before,
        &::after {
            position: absolute;
            content: '';
            border-bottom: $border-width-medium $border-style-solid $color-monochrome-300;
            width: $border-radius-half;
            top: $space-75;
            z-index: 2;

            @media screen and (min-width: $breakpoint-medium) {
                top: $space-75 + $space-15;
            }
        }

        &::before {
            right: $border-radius-half;
        }

        &::after {
            left: $border-radius-half;
        }
    }

    &__step-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: none;
        width: $full-width;

        &:focus-visible {
            @include brix-focus(true);
        }
    }

    &__step &__indicator {
        position: relative;
        z-index: 5;
        width: $size-icon-100;
        height: $size-icon-100;
        border-radius: $border-radius-half;
        background-color: $color-monochrome-300;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $space-15 + $space-25;

        @media screen and (min-width: $breakpoint-medium) {
            width: $size-icon-125;
            height: $size-icon-125;
            margin-bottom: $space-50;
        }
    }

    &__step--finished {
        &::after {
            position: absolute;
            content: '';
            border-bottom: $border-width-medium $border-style-solid $color-primary-100;
            width: $border-radius-half;
            top: $space-75;
            left: $border-radius-half;
            z-index: 3;

            @media screen and (min-width: $breakpoint-medium) {
                top: $space-75 + $space-15;
            }
        }
    }

    &__step--finished &__step-item:not([disabled]) {
        cursor: pointer;
    }

    &__step--finished &__step-item:disabled {
        pointer-events: none;
    }

    &__step:first-child::before,
    &__step:last-child::after {
        content: none;
    }

    &__text-label {
        font-size: $font-size-100;
        color: $color-monochrome-600;
        line-height: $line-height-100;
        display: none;
        margin-bottom: $space-0;
        text-align: center;

        @media screen and (min-width: $breakpoint-medium) {
            font-size: $font-size-150;
            display: block;
        }
    }

    &__step--active::before {
        border-bottom-color: $color-primary-100;
    }

    &__step--active &__indicator {
        background-color: $color-primary-100;
    }

    &__step--active-circle &__text-label {
        font-weight: $font-weight-bold;
        display: block;
    }

    &__step--active-circle {
        $line-small: calc($border-radius-half + $space-75 - 1px);
        $line-lg: calc($border-radius-half + $space-75 + 1px);

        &::before {
            right: $line-small;

            @media screen and (min-width: $breakpoint-medium) {
                right: $line-lg;
            }
        }

        &::after {
            left: $line-small;

            @media screen and (min-width: $breakpoint-medium) {
                left: $line-lg;
            }
        }
    }

    &__step--active-circle &__indicator::before {
        content: '';
        position: absolute;
        top: -$space-25;
        left: -$space-25;
        right: -$space-25;
        bottom: -$space-25;
        border: $space-15 $border-style-solid $color-primary-100;
        border-radius: $border-radius-half;
    }

    &__step--finished &__step-item:hover &__indicator,
    &__step--finished &__step-item:focus-visible &__indicator {
        background-color: hover($color-primary-100);
    }

    &__step--finished &__step-item:hover &__text-label,
    &__step--finished &__step-item:focus-visible &__text-label {
        font-weight: $font-weight-bold;
    }

    &__step--finished &__step-item:active &__indicator {
        background-color: press($color-primary-100);
    }

    &__footnote {
        p {
            margin-top: $space-75;

            @media screen and (min-width: $breakpoint-medium) {
                margin-top: $space-100;
            }
        }
    }

    &--inverse &__text-label,
    &--inverse &__footnote p {
        color: $color-monochrome-600-inverse;
    }

    &--inverse &__step &__indicator {
        background-color: $color-monochrome-300-inverse;
    }

    &--inverse &__step--active &__indicator {
        background-color: $color-primary-100-inverse;
    }

    &--inverse &__step::before,
    &--inverse &__step::after {
        border-bottom-color: $color-monochrome-300-inverse;
    }

    &--inverse &__step--finished::after,
    &--inverse &__step--active::before {
        border-bottom-color: $color-primary-100-inverse;
    }

    &--inverse &__step--active-circle &__indicator::before {
        border-color: $color-primary-100-inverse;
    }

    &--inverse &__step--finished &__step-item:hover &__indicator,
    &--inverse &__step--finished &__step-item:focus-visible &__indicator {
        background-color: hover($color-primary-100-inverse);
    }

    &--inverse &__step--finished &__step-item:active &__indicator {
        background-color: press($color-primary-100-inverse);
    }

    &__dynamic-buttons {
        margin-top: $space-150;

        @media screen and (min-width: $breakpoint-small) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__dynamic-contents {
        margin-top: $space-100;

        @media screen and (min-width: $breakpoint-medium) {
            margin-top: $space-150;
        }
    }

    &__dynamic-button-next {
        @media screen and (min-width: $breakpoint-small) {
            margin-bottom: 0 !important;
        }
    }
}
