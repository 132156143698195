/* stylelint-disable */
@import "../styles/scss/precss/precss";
@import "../youtube-video/youtube-video";
@import "../video/video";
@import "../image/image";

$media-text-class-prefix: 'ws10-media-text';

.#{$media-text-class-prefix} {
    @include ws10-text(false);

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: span 12;
    column-gap: $space-50;

    @include mq(small) {
        column-gap: $space-100;
    }

    @include mq(large) {
        column-gap: $space-200;
    }

    .#{$media-text-class-prefix}__media {
        grid-column: span 12;

        @include mq(large) {
            grid-column: span 6;
        }
    }

    .#{$media-text-class-prefix}__segment {
        grid-column: span 12;

        @include mq(large) {
            grid-column: span 6;
        }
    }

    &--media-right &__segment {
        order: -1;
    }

    &--media-top &__segment {
        grid-column: span 12;

        @include mq(large) {
            grid-column: span 10;
        }
    }

    &--media-top &__media {
        grid-column: span 12;
    }

    &-column {
        &--12 {
            &.#{$media-text-class-prefix}--media-right .#{$media-text-class-prefix}__segment {
                order: 1;

                @include mq(large) {
                    order: -1;
                }
            }
        }

        &--6 {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column: span 12;

            @include mq(large) {
                grid-template-columns: repeat(6, 1fr);
                grid-column: span 6;
            }

            .#{$media-text-class-prefix}__media {
                grid-column: span 3;

                @include mq(large) {
                    grid-column: span 2;
                }
            }

            .#{$media-text-class-prefix}__segment {
                grid-column: span 9;

                @include mq(large) {
                    grid-column: span 4;
                }
            }
        }

        &--4 {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column: span 12;

            @include mq(large) {
                grid-template-columns: repeat(4, 1fr);
                grid-column: span 4;
            }

            .#{$media-text-class-prefix}__media {
                grid-column: span 3;

                @include mq(large) {
                    grid-column: span 2;
                }
            }

            .#{$media-text-class-prefix}__segment {
                grid-column: span 9;

                @include mq(large) {
                    grid-column: span 2;
                }
            }
        }

        &--3 {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            grid-column: span 12;

            @include mq(large) {
                grid-template-columns: repeat(3, 1fr);
                grid-column: span 3;
            }

            .#{$media-text-class-prefix}__media {
                grid-column: span 3;

                @include mq(large) {
                    grid-column: span 1;
                }
            }

            .#{$media-text-class-prefix}__segment {
                grid-column: span 9;

                @include mq(large) {
                    grid-column: span 2;
                }
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        @include mq(large) {
            column-gap: $space-200;
        }
    }

    &__media,
    &__headline,
    &__copytext {
        margin-bottom: $space-100;

        // remove margin from youtube video, because it creates double margin in media text
        .ws10-youtube-video {
            margin-bottom: 0;
        }
    }

    &__buttons {
        @include mq(small) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__button-container:nth-of-type(1) {
        margin-bottom: $space-100;

        @include mq(small) {
            margin-right: $space-75;
        }
    }
}
