@import '../styles/scss/precss/precss';
@import '../form-text-input/form-text-input';
@import '../button-icon-only/button-icon-only';

$search-input-wrapper: $ws10-css-prefix + '-search-input-wrapper';

.#{$search-input-wrapper} {
    @include ws10-text(false);

    display: flex;

    .#{$ws10-css-prefix}-button-icon-only--primary {
        // fix focus box shadow z-index issue
        z-index: 0;
        position: relative;
    }

    .#{$ws10-css-prefix}-form-text-input-container {
        flex-grow: 1;

        .#{$ws10-css-prefix}-form-text-input__system_icon-container {
            &:focus-visible {
                .#{$ws10-css-prefix}-system-icon {
                    border: 2px solid $color-secondary-600;
                }
            }
        }
        .#{$ws10-css-prefix}-system-icon {
            &:hover {
                color: $color-primary-200;
            }
        }
    }

    &--inverse {
        .#{$ws10-css-prefix}-form-text-input .#{$ws10-css-prefix}-form-text-input__input {
            border-color: $color-monochrome-400-inverse;

            &:focus {
                border-color: $color-secondary-600-inverse;
                box-shadow: inset 0 0 0 1px #5f9bc8;
            }
        }

        .#{$ws10-css-prefix}-form-text-input-container {
            .#{$ws10-css-prefix}-form-text-input__system_icon-container {
                &:focus-visible {
                    .#{$ws10-css-prefix}-system-icon {
                        border: 2px solid #5f9bc8;
                    }
                }
            }
        }

        .#{$ws10-css-prefix}-button-icon-only--primary {
            // enabling tab-only focus
            &:focus-visible:not([disabled]) {
                box-shadow: 0 0 0 -2px #fff, 0 0 0 0 #fff, 0 0 0 2px #5f9bc8, 0 0 0 4px #fff;
            }
        }

        &.#{$search-input-wrapper}--disabled {
            .#{$ws10-css-prefix}-form-text-input .#{$ws10-css-prefix}-form-text-input__input {
                background-color: $color-monochrome-200-inverse;
                border-color: $color-monochrome-300-inverse;
                color: $color-monochrome-300-inverse;
            }

            .#{$ws10-css-prefix}-system-icon {
                color: $color-monochrome-300-inverse;
            }

            .#{$ws10-css-prefix}-button-icon-only--primary {
                background-color: $color-monochrome-300-inverse;
            }

            .#{$ws10-css-prefix}-button-icon-only--primary .#{$ws10-css-prefix}-button-icon-only__icon {
                color: $color-monochrome-300;
            }

            .#{$ws10-css-prefix}-button-icon-only--disabled {
                width: $size-button-large;
                height: $size-button-large;

                @include mq(small) {
                    width: $size-button-x-large;
                    height: $size-button-x-large;
                }
            }
        }
    }

    .#{$ws10-css-prefix}-form-text-input .#{$ws10-css-prefix}-form-text-input__input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .#{$ws10-css-prefix}-button-icon-only--square {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .#{$ws10-css-prefix}-button-icon-only--standard {
        width: $size-button-large;
        height: $size-button-large;

        @include mq(small) {
            width: $size-button-x-large;
            height: $size-button-x-large;
        }
    }
}
