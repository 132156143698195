@import '../styles/scss/precss/precss';

$system-icon-prefix: 'ws10-notification-icon';

.#{$system-icon-prefix} {
    @include ws10-text(false);

    width: $size-icon-150;
    height: $size-icon-150;

    &--success {
        color: $color-secondary-500;
    }

    &--info {
        color: $color-secondary-600;
    }

    &--error {
        color: $color-primary-200;
    }

    &--warn {
        color: $color-secondary-300;
    }

    &--color-inherit {
        color: inherit;
    }
}
