@import '../styles/scss/precss/precss';

$image-class-prefix: 'ws10-image';

.#{$image-class-prefix} {
    display: block;

    source,
    img {
        max-width: 100%;
    }

    &--width-full {
        width: 100%;

        source,
        img {
            width: 100%;
        }

        img {
            display: block;
        }
    }

    &-object-fit {
        &--none {
            object-fit: none;
        }

        &--contain {
            object-fit: contain;
        }

        &--cover {
            object-fit: cover;
        }

        &--scale-down {
            object-fit: scale-down;
        }
    }

    &-link:focus img {
        @include brix-focus(false);
    }
}
