@import '../styles/scss/precss/precss';

$video-class-prefix: 'ws10-video';

.#{$video-class-prefix} {
    @include ws10-text(false);

    width: 100%;

    &[poster] {
        object-fit: cover;
    }
}
