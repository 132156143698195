/**
 * Shared mixins to style at least form-textarea, form-select and form-text-input
 */

/**
 * For forms elements (e.g. textarea, text-input and select) we have different states with different visual border width and colors
 * All form element s should align to each other never the less wich state the have
 * and each form element should be able to change their state without wiggle it's position or it's content
 *
 * Therefore this mixin takes a $color and returns a
 * border: 1px solid $color
 * and
 * box-shadow: inset 0px 0px 0px 1px $color
 *
 * So pretends to have a 2px border, but it aligns with every form-element that has a  1px border
 * And you can switch between the 2px "border" and a 1px border without any change in elements position
 *
 * border-width or border-style is not part of this mixin, because that should be applied as default to the form-element and
 * will not change
 */
@mixin pretend-you-are-wider($color) {
    border-color: $color;
    box-shadow: inset 0 0 0 1px $color;
}

@mixin form-element-border($wrapper, $form-element) {
    .#{$wrapper} {
        @include ws10-text;

        .#{$form-element} {
            border-radius: $border-radius-medium;
            border-style: $border-style-solid;
            border-width: $border-width-small;
            border-color: $color-monochrome-500;

            &:active,
            &:focus {
                @include pretend-you-are-wider($color-secondary-600);
            }
        }

        &--error {

            .#{$form-element} {
                @include pretend-you-are-wider($color-primary-200);
            }
        }

        &--warn {

            .#{$form-element} {
                @include pretend-you-are-wider($color-secondary-300);
            }
        }

        &--success {

            .#{$form-element} {
                @include pretend-you-are-wider($color-secondary-500);
            }
        }

        &--disabled {

            .#{$form-element} {
                border-color: $color-monochrome-300;
                background: $color-monochrome-200;
                color: $color-monochrome-300;
                cursor: not-allowed;
            }
        }
    }
}

/**
 * form-elements (textarea, text-input, select) can have notification-icons depending on their state:
 * form-elements (text-input, select) can have an system-icon. For text-input its optional and select always have a chevron-down
 *
 * if present, system-icon is always the most right one.
 *
 * when having a system-icon the notification icon aligns left to that one
 */
@mixin form-element-icons($wrapper, $form-element) {

    .#{$wrapper} {
        @include ws10-text(false);

        position: relative;

        .#{$ws10-css-prefix}-notification-icon {
            visibility: hidden;
            pointer-events: none;
            position: absolute;
            top: $space-75;
            right: $space-100;
            width: $size-icon-150;
            height: $size-icon-150;

            @include mq(small) {
                top: $space-100;
            }
        }

        &--error {

            .#{$ws10-css-prefix}-notification-icon {
                visibility: visible;
            }
        }

        &--warn {

            .#{$ws10-css-prefix}-notification-icon {
                visibility: visible;
            }
        }

        &--success {

            .#{$ws10-css-prefix}-notification-icon {
                visibility: visible;
            }
        }

        .#{$ws10-css-prefix}-system-icon {
            position: absolute;
            top: $space-75;
            right: $space-100;
            width: $size-icon-150;
            height: $size-icon-150;

            @include mq(small) {
                width: $size-icon-200;
                height: $size-icon-200;
            }
        }

        &--has-system-icon {

            .#{$ws10-css-prefix}-notification-icon {
                right: $space-100 + $size-icon-150 + $space-50;

                @include mq(small) {
                    right: $space-100 + $size-icon-200 + $space-50;
                }
            }
        }
    }
}

@mixin form-element-height($wrapper, $form-element) {
    $default-padding-right: $space-150 + $space-200;

    .#{$wrapper} {
        @include ws10-text(false);

        margin-bottom: $space-50;

        $padding-with-border-in-mind: ($space-75 - 1) $default-padding-right ($space-75 - 1) $space-100;

        .#{$form-element} {
            width: 100%;
            display: block;
            padding: $padding-with-border-in-mind;
            background-color: $color-monochrome-100;
            outline: none;
            appearance: none;
            color: $color-monochrome-600;
            font-weight: $font-weight-normal;
            font-family: $font-vodafone;
            font-size: $font-size-150;
            line-height: $line-height-fix-150;

            @include mq(small) {
                font-size: $font-size-200;
                line-height: $line-height-fix-200;
            }

            // stylelint-disable-next-line selector-no-vendor-prefix
            &::placeholder,
            &::-webkit-input-placeholder {
                color: $color-monochrome-400;
            }
        }

        /**
         * Using disabled class instead of pseudo :disabled
         * While there will be the requirement to style a disabled form element together with a label
         */
        &--disabled {

            .#{$form-element} {
                cursor: not-allowed;
                background-color: $color-monochrome-200;
                color: $color-monochrome-300;

                &::placeholder {
                    color: $color-monochrome-300;
                }
            }

            /**
             * Two options here:
             * Change the disabled icon color here
             * or rendern the correct color in hbs.
             *
             * I decided to to it here, while it would be an if mess in hbs.
             * After refactoring the state and decouple "disabled" and ["success"|"error"]
             * it might be an a more stringent variant to do it in hbs
             */
            .#{$ws10-css-prefix}-system-icon {
                color: $color-monochrome-300;
            }
        }
    }
}
