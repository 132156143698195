@import '../styles/scss/precss/precss';

$loading-animation-class-prefix: 'ws10-loading-animation';
$loading-animation-dot-size-large: 12px;
$loading-animation-dot-size-medium: 8px;
$loading-animation-space-between-dots-large: $space-50;
$loading-animation-space-between-dots-medium: $space-25;
$loading-animation-duration: $duration-1000 + $duration-500;

.#{$loading-animation-class-prefix} {
    position: relative;

    &__dots {
        display: flex;
        height: $loading-animation-dot-size-large + $space-50;
    }

    &__dot {
        position: relative;
        top: $space-50;
        width: $loading-animation-dot-size-large;
        height: $loading-animation-dot-size-large;
        margin-right: $loading-animation-space-between-dots-large;
        border-radius: 50%;
        background: $color-monochrome-600;
        animation: #{$loading-animation-class-prefix}-large $loading-animation-duration linear infinite;

        &:nth-child(1) {
            animation-delay: -$duration-1000;
        }

        &:nth-child(2) {
            animation-delay: -$duration-800;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__alert {
        position: absolute;
        width: $space-0;
        height: $space-0;
        border: $space-0;
        padding: $space-0;
        margin: $space-0;
        overflow: hidden;
    }

    &--size-medium {
        .#{$loading-animation-class-prefix} {
            height: $loading-animation-dot-size-medium + $space-25;

            &__dots {
                height: $loading-animation-dot-size-large + $space-25;
            }

            &__dot {
                top: $space-25;
                width: $loading-animation-dot-size-medium;
                height: $loading-animation-dot-size-medium;
                margin-right: $loading-animation-space-between-dots-medium;
                animation-name: #{$loading-animation-class-prefix}-medium;
            }
        }
    }
}

@keyframes #{$loading-animation-class-prefix}-large {
    0% {
        opacity: $opacity-20;
    }

    10% {
        opacity: 1;
        transform: initial;
    }

    50% {
        transform: translateY(#{-$space-50});
    }

    90% {
        transform: initial;
    }

    100% {
        opacity: $opacity-20;
    }
}

@keyframes #{$loading-animation-class-prefix}-medium {
    0% {
        opacity: $opacity-20;
    }

    10% {
        opacity: 1;
        transform: initial;
    }

    50% {
        transform: translateY(#{-$space-25});
    }

    90% {
        transform: initial;
    }

    100% {
        opacity: $opacity-20;
    }
}
