// import only precss from styles package! This contains all your variables you need!
@import '../styles/scss/precss/precss';

$sticky-price-bar-class-prefix: $ws10-css-prefix + '-sticky-price-bar';
$minimum-breakpoint: 360px;
$animation-duration: 250ms;

@keyframes scale-update-animation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.#{$sticky-price-bar-class-prefix} {
    position: fixed;
    left: $space-100;
    right: $space-100;
    bottom: $space-200;
    z-index: 1;
    box-shadow: $shadow-large;
    border-radius: $border-radius-medium;
    background: $color-monochrome-600;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translateY(50%);

    @media (min-width: $minimum-breakpoint) and (max-width: $breakpoint-small) {
        transition: all $animation-duration $ease-in-sine;
    }

    &--visible {
        @media (min-width: $minimum-breakpoint) and (max-width: $breakpoint-small) {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }

    &--updated {
        animation: scale-update-animation $animation-duration $ease-in-sine;
    }

    &__content {
        padding: $space-75 $space-125;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: $space-75;
        column-gap: $space-200;
    }

    &__item {
        display: flex;
        column-gap: $space-50;

        &:nth-child(3n) {
            width: 100%;
            justify-content: center;

            .#{$sticky-price-bar-class-prefix}__item-text {
                @include ws10-text-smaller-inverse;

                line-height: $line-height-reset;
            }

            .#{$sticky-price-bar-class-prefix}__item-price-section {
                @include ws10-text-smaller-inverse;

                font-weight: $font-weight-bold;
                line-height: $line-height-reset;
            }
        }
    }

    &__item-text {
        @include ws10-text-small-inverse;

        line-height: $line-height-reset;
        margin-top: auto;
    }

    &__item-price-section {
        @include ws10-text-large-inverse;

        line-height: $line-height-reset;
        font-weight: $font-weight-bold;
    }
}
