@use 'sass:map';
@import '../styles/scss/precss/precss';
@import '../highlight-badge/highlight-badge';
@import '../button/button';
@import '../promo-price/promo-price';

$offer-summary-card-prefix: $ws10-css-prefix + '-offer-summary-card';
$offer-summary-card-max-width: 800px;
$highlight-badge-colors: 'green', 'yellow', 'red', 'burgundy', 'black';
$button-colors: primary, secondary,  tertiary, disabled;

.#{$offer-summary-card-prefix} {
    @include ws10-text(false);
    @include card-base;

    max-width: $offer-summary-card-max-width;

    @include mq(mobile-only) {
        background-color: $color-monochrome-600;
    }

    &__content {
        &--top,
        &--middle {
            border-bottom: $border-solid-grey;
            margin-bottom: $space-100;
        }

        &--top {
            padding-bottom: $space-100;
        }

        &--bottom {
            display: flex;
            flex-direction: column;
        }

        @include mq(mobile-only) {
            @each $highlight-badge-color in $highlight-badge-colors {
                .#{$ws10-css-prefix}-highlight-badge--#{$highlight-badge-color},
                .#{$ws10-css-prefix}-highlight-badge--#{$highlight-badge-color}-inverse {
                    $inverse-variation: map.get($badge-color-variations, #{$highlight-badge-color}-inverse);
                    $content-color: map.get($inverse-variation, content-color);

                    background-color: map.get($inverse-variation, background-color);
                    color: $content-color;
                }
            }

            .#{$ws10-css-prefix}-button.#{$ws10-css-prefix}-button--tertiary {
                @include tertiary-behavior($color-monochrome-600-inverse, $color-monochrome-100-inverse, $color-monochrome-100-inverse, $color-monochrome-600-inverse);
            }

            @each $button-color in $button-colors {
                .#{$ws10-css-prefix}-button.#{$ws10-css-prefix}-button--#{$button-color} {
                    $variant: map.get($button-color-variations, #{$button-color}-inverse);

                    @include button-color-scheme($variant);
                }
            }

            .#{$ws10-css-prefix}-promo-price {
                color: $color-monochrome-600-inverse;

                &__strike-price {
                    color: $color-primary-200-inverse;
                }
            }

            .#{$ws10-css-prefix}-text-link {
                @include ws10-text-link($color-monochrome-600-inverse, $color-primary-200-inverse, $color-monochrome-300-inverse);
            }
        }
    }

    &__headline {
        > * {
            margin-bottom: 0;

            @include mq(mobile-only) {
                // important is needed because depending on the order of the styles the ws10-headline style from another repo could overwrite this
                // stylelint-disable-next-line declaration-no-important
                color: $color-monochrome-600-inverse !important;
            }
        }

        + .#{$offer-summary-card-prefix}__subline-text {
            margin-top: $space-50;
        }
    }

    &__subline-text,
    &__legal-text,
    &__additional-text {
        > * {
            margin-bottom: $space-50;

            @include mq(mobile-only) {
                color: $color-monochrome-600-inverse;
            }
        }

        :last-child {
            // remove bottom margin of last paragraphs etc.

            /* stylelint-disable-next-line declaration-no-important */
            margin-bottom: 0 !important;
        }
    }

    &__legal-text {
        > * {
            @include mq(small) {
                margin-bottom: $space-100;
            }
        }
    }

    &__additional-text {
        // the additional-text comes before the buttons in the DOM
        // but visually we want to have it below the buttons
        order: 1;
    }

    &__highlight-badge,
    &__legal-text,
    &__buttons,
    &__button {
        margin-bottom: $space-100;
    }

    &__button {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__prices {
        margin-bottom: $space-50;

        @include mq(small) {
            margin-bottom: $space-100;
        }
    }

    &__price-table {
        width: 100%;

        caption,
        tbody,
        tr,
        td,
        th {
            // revert all global table styles from simplicity
            all: revert;
        }

        th,
        td {
            padding: 0;
        }

        th {
            font-weight: $font-weight-normal;
            text-align: left;
        }
    }

    &__price-row {
        th {
            padding-right: $space-50;

            @include mq(small) {
                padding-right: $space-100;
            }
        }

        td {
            // the 1% makes sure the tds shrink as much as possible (and ths grow as much as possible)
            width: 1%;
            text-align: right;
        }

        &--additional-price {
            &:not(:first-child) {
                th,
                td {
                    padding-top: $space-50;

                    @include mq(small) {
                        padding-top: $space-100;
                    }
                }
            }

            .#{$offer-summary-card-prefix} {
                &__price-value {
                    @include ws10-text-large;

                    font-weight: $font-weight-bold;
                }

                &__price-label,
                &__price-value {
                    @include mq(mobile-only) {
                        color: $color-monochrome-600-inverse;
                    }
                }
            }
        }

        &--promo-price {
            $price-label-bottom-offset: -5px;

            th {
                position: relative;
            }

            .#{$offer-summary-card-prefix} {
                &__price-label {
                    @include ws10-text-large;

                    position: absolute;
                    left: 0;
                    bottom: $price-label-bottom-offset;
                    font-weight: $font-weight-bold;

                    @include mq(mobile-only) {
                        color: $color-monochrome-600-inverse;
                    }
                }
            }

            + .#{$offer-summary-card-prefix} {
                &__price-row {
                    &--promo-price {
                        th,
                        td {
                            padding-top: $space-125;

                            @include mq(small) {
                                padding-top: $space-150;
                            }
                        }
                    }

                    &--additional-price {
                        th,
                        td {
                            padding-top: $space-125;

                            @include mq(small) {
                                padding-top: $space-150;
                            }
                        }
                    }
                }
            }

            &:last-child {
                th,
                td {
                    padding-bottom: $space-75;

                    @include mq(small) {
                        padding-bottom: $space-50;
                    }
                }

                .#{$offer-summary-card-prefix} {
                    &__price-label {
                        bottom: ($space-75 + $price-label-bottom-offset);

                        @include mq(small) {
                            bottom: ($space-50 + $price-label-bottom-offset);
                        }
                    }
                }
            }
        }
    }

    &__price-label {
        text-align: left;
    }

    &__promo-price {
        .#{$ws10-css-prefix}-promo-price {
            &__condition-after {
                // The condition-after should always be filled so the screenreader reads the sr-only text correctly
                // but we hide it visually since we show the label in the price-table on the left already
                display: none;
            }
        }
    }
}
