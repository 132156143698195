@import '../styles/scss/precss/precss';

$app-store-buttons-class-prefix: 'ws10-app-store-buttons';
.#{$app-store-buttons-class-prefix} {
    @include ws10-text(false);

    display: flex;

    &__button-standard {
        background-color: $color-monochrome-600;
        margin-right: $space-100;
        border-radius: $border-radius-medium;
        padding: $space-50 $space-75;
        height: $space-300;

        &:focus-visible {
            @include brix-focus(false);

            background-color: hover($color-monochrome-600);
        }

        &:hover {
            background-color: hover($color-monochrome-600);
            cursor: pointer;
        }

        &:active {
            background-color: press($color-monochrome-600);
        }
    }

    &__button-small {
        background-color: $color-monochrome-600;
        margin-right: $space-100;
        border-radius: $border-radius-medium;
        height: $space-200;
        padding: $space-25 $space-100;

        &:focus-visible {
            @include brix-focus(false);

            background-color: hover($color-monochrome-600);
        }

        &:hover {
            background-color: hover($color-monochrome-600);
            cursor: pointer;
        }

        &:active {
            background-color: press($color-monochrome-600);
        }
    }

    &__inverse-small {
        background-color: $color-monochrome-600-inverse;
        margin-right: $space-100;
        border-radius: $border-radius-medium;
        height: $space-200;
        padding: $space-25 $space-100;

        &:focus-visible {
            @include brix-focus(false);

            background-color: hover($color-monochrome-600-inverse);
        }

        &:hover {
            background-color: hover($color-monochrome-600-inverse);
            cursor: pointer;
        }

        &:active {
            background-color: press($color-monochrome-600-inverse);
        }
    }

    &__inverse-standard {
        background-color: $color-monochrome-600-inverse;
        margin-right: $space-100;
        border-radius: $border-radius-medium;
        padding: $space-50 $space-75;
        height: $space-300;
        display: inline-block;

        &:focus-visible {
            @include brix-focus(false);

            background-color: hover($color-monochrome-600-inverse);
        }

        &:hover {
            background-color: hover($color-monochrome-600-inverse);
            cursor: pointer;
        }

        &:active {
            background-color: press($color-monochrome-600-inverse);
        }
    }

    &__image-standard {
        width: $size-icon-750;
        height: $size-icon-200;
    }

    &__image-small {
        width: $size-icon-600;
        height: $size-icon-150;
    }

    @include mq(mobile-only) {
        display: inline-grid;

        &__image-standard {
            width: $size-icon-750;
            height: 28px;
        }

        &__button-standard,
        &__inverse-standard {
            height: 44px;
            margin-bottom: $space-100;
        }

        &__flex-direction-small {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        &__button-small,
        &__inverse-small {
            margin-bottom: $space-100;
        }
    }
}
