@import '../styles/scss/precss/precss';

$youtube-video-class-prefix: 'ws10-youtube-video';

.#{$youtube-video-class-prefix} {
    @include ws10-text(false);

    display: flex;
    margin-bottom: $space-100;
    position: relative;

    &__embed-youtube {
        position: relative;
        padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
        overflow: hidden;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
