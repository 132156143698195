@import '../styles/scss/precss/precss';

$icon-text-list-class-prefix: '#{$ws10-css-prefix}-icon-text-list';

.#{$icon-text-list-class-prefix} {
    &--spaced {
        .#{$icon-text-list-class-prefix} {
            &__headline {
                margin-bottom: $space-100;
            }

            &__list-item {
                margin-bottom: $space-100;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
