@import '../styles/scss/precss/precss';

$element: $ws10-css-prefix + '-form-textarea';
$textarea: $element + '__textarea';

@include form-element-border($element, $textarea);
@include form-element-icons($element, $textarea);
@include form-element-height($element, $textarea);

.#{$element} {
    @include ws10-text(false);

    .#{$textarea} {
        resize: vertical;
    }

    &--auto-height {
        .#{$textarea} {
            resize: none;
            overflow-y: hidden;
            min-height: $line-height-fix-150 + 2 * $space-75;
            height: $line-height-fix-150 + 2 * $space-75;
            max-height: 5 * $line-height-fix-150 + 2 * $space-75;

            @include mq(small) {
                min-height: $line-height-fix-200 + 2 * $space-75;
                height: $line-height-fix-200 + 2 * $space-75;
                max-height: 5 * $line-height-fix-200 + 2 * $space-75;
            }
        }
    }
}
