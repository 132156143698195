@use 'sass:map';
@use 'sass:math';
@import '../styles/scss/precss/precss';

$grid: $ws10-css-prefix + '-grid';
$column: $ws10-css-prefix + '-column';

.#{$grid} {
    $max-grid-columns: 12;
    $grid-gutters: (
        default: $space-100,
        large: $space-200,
        small: $space-50,
        no: $zero,
    );

    // flow-root is used so that the negative top margin of __inner does not pull up the parent with it
    display: flow-root;

    @mixin make-grid($gutter: $grid-gutters, $default: default) {
        display: flex;
        flex-wrap: wrap;

        .#{$grid}--no-vertical-gutter & {
            margin-top: 0;
        }
    }

    @mixin make-gutter($gutter) {
        $size: calc($gutter / 2);

        margin: (-$gutter) (-$size) 0 (-$size);
        width: calc(100% + #{$gutter});

        .#{$column} {
            padding-left: $size;
            padding-right: $size;
            padding-top: $gutter;
        }
    }

    @mixin make-gutters($gutter: $grid-gutters, $default: default) {
        @each $name, $size in $gutter {
            @if $name == $default {
                @include make-gutter($size);

                @include mq(large) {
                    @include make-gutter(map.get($gutter, large));
                }
            }
            @else {
                &.#{$grid}--#{$name}-gutter {
                    @include make-gutter($size);
                }
            }
        }
    }

    @mixin make-col-ready() {
        box-sizing: border-box;
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: 100%;
        max-width: 100%;

        @at-root .#{$ws10-css-prefix}-grid--no-vertical-gutter & {
            padding-top: 0;
        }
    }

    @mixin make-col($size, $columns: $max-grid-columns) {
        flex-basis: math.percentage(calc($size / $columns));
        max-width: math.percentage(calc($size / $columns));
    }

    @mixin make-cols($columns: $max-grid-columns, $breakpoints: $breakpoints) {
        @each $name, $mqValue in $breakpoints {
            $class: breakpoint-append-classname($name, $breakpoints);

            @include mq($name) {
                @for $count from 1 through $max-grid-columns {
                    .#{$column}-#{$class}-#{$count} {
                        @include make-col($count);
                    }

                    .#{$column}-order-#{$class}-#{$count} {
                        order: $count;
                    }
                }
            }
        }
    }

    &__inner {
        @include make-grid;
        @include make-gutters;

        .#{$column} {
            @include make-col-ready;
        }

        @include make-cols;
    }
}
