@import '../styles/scss/precss/precss';
@import '../button-link/button-link';

$card-class-prefix: $ws10-css-prefix + '-collapsable-card';
$card-class-button-link: 'ws10-button-link';
$card-class-button-link-icon: $card-class-button-link + '__icon';

/**
 * This collapsable-card is a special case where we can save some code ;)
 * It is specified with white and dark-grey and in inversed
 * And white inversed is specified with inversed tokens that appear to have the very same values
 * as dark-grey
 *
 * As soon as there will be new themes we should remove this shortcut and implement a mapping like in
 * packages/highlight-badge/highlight-badge.scss
 */
$light-background: $color-monochrome-100;
$light-link-color: $color-primary-200;
$dark-background: $color-monochrome-500;
$dark-link-color: $color-primary-200-inverse;

@mixin collapsable-card-color-scheme($background, $link-color) {
    background-color: $background;

    .#{$card-class-button-link} {
        color: $link-color;
    }
    .#{$card-class-button-link-icon} {
        color: $link-color;
    }
}

.#{$card-class-prefix} {
    @include ws10-text(false);

    width: 100%;
    display: inline-block;
    position: relative;
    border: $border-none;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-medium;
    padding: $space-100;

    @include mq(medium) {
        padding: $space-125;
    }

    @include collapsable-card-color-scheme($light-background, $light-link-color);

    &--inverse {
        @include collapsable-card-color-scheme($dark-background, $dark-link-color);

        /* @TODO Disuse with UX how to solve children within a inverse container */
        .#{$card-class-prefix}__content * {
            color: $color-monochrome-600-inverse;
        }
    }

    &--dark-grey {
        @include collapsable-card-color-scheme($dark-background, $dark-link-color);

        /* @TODO Disuse with UX how to solve children within a inverse container */
        .#{$card-class-prefix}__content * {
            color: $color-monochrome-600-inverse;
        }
    }
    &--dark-grey#{&}--inverse {
        @include collapsable-card-color-scheme($light-background, $light-link-color);

        /* @TODO Disuse with UX how to solve children within a inverse container */
        .#{$card-class-prefix}__content * {
            color: inherit;
        }
    }

    &__content {
        max-height: 102vh;
        overflow: hidden;
        transition: max-height $duration-500 $ease-in-out-sine;
    }

    &--collapsed &__content {
        max-height: 200px;
    }

    &__toggle {
        text-align: center;
        padding-top: $space-100;

        @include mq(medium) {
            padding-top: $space-125;
        }
        .#{$card-class-button-link} {
            cursor: pointer;

            &:focus {
                text-decoration: none;
            }

            &__icon {
                transition: transform $duration-500 $ease-in-out-sine;
            }
        }
    }
    &--collapsed .#{$card-class-button-link-icon} {
        transform: rotate(180deg);
    }
}
