@import '../styles/scss/precss/precss';

$upload-class-prefix: $ws10-css-prefix + '-upload';
.#{$upload-class-prefix} {
    &-headline {
        margin-bottom: $space-75;
        color: $color-monochrome-600;
    }

    &-text {
        color: $color-monochrome-600;
    }

    &-text_spaced {
        margin-bottom: $space-75;
    }

    &-form-text-input-container--hidden {
        display: none;
    }

    &-button-container {
        padding-top: $space-75;
    }

    &-file {
        border-top: $border-width-small solid $color-monochrome-300;
        border-bottom: $border-width-small solid $color-monochrome-300;
        padding-top: $space-25;
        padding-bottom: $space-25;
        display: flex;
        flex-flow: row wrap;
    }

    &-file + &-file {
        border-top: none;
    }

    &-file + &-length-error-notification-container,
    &-size-error-notification-container {
        padding-top: $space-75;
    }

    &-tick-container {
        padding-right: $space-50;
        display: flex;
        flex-flow: row-reverse wrap;
        align-items: center;
        flex-grow: 1;
        height: $space-300;
    }

    &-file--err {
        flex-flow: nowrap;
        padding-top: $space-75;
    }

    &-file__name {
        padding-left: $space-75;
    }

    &-label-container {
        width: $full-percent;
        padding-top: $space-25;
    }

    &-label-container > * {
        font-size: $font-size-50 !important;
    }

    &-delete {
        display: flex;
        flex-flow: row-reverse wrap;
        flex-grow: 1;
        padding-right: $space-25;
    }

    &-delete-err {
        display: flex;
        flex-flow: column-reverse;
        align-items: flex-end;
        text-align: right;
    }

    &-loading-animation-container {
        display: flex;
        flex-flow: row-reverse wrap;
        align-items: center;
        flex-grow: 1;
        height: $space-300;
        padding-right: $space-25;
    }

    &-reupload-button-container {
        padding-top: $space-25;
        height: $space-300;
        display: flex;
        align-items: center;
    }

    &-file-data-container_success {
        display: flex;
        align-items: center;
    }

    &-file-icon-container {
        padding-right: $space-75;
        display: flex;
        align-items: center;
    }

    &-file-icon-container-err {
        display: block;
    }
}
