@use 'sass:map';
@import 'flag-badge-variables';

:root {
    @include set-custom-property('flag-badge-height', $space-15 * 2 + $font-size-100 * $line-height-100);
    @include set-custom-property('flag-badge-small-height', $space-15 * 2 + $font-size-50 * $line-height-100);

    @include mq(large) {
        @include set-custom-property('flag-badge-height', $space-15 * 2 + $font-size-150 * $line-height-100);
    }
}

.#{$flag-badge-class-prefix} {
    display: inline-block;
    height: get-custom-property('flag-badge-height');
    padding: $space-15 $space-75;
    font-size: $font-size-100;
    font-weight: $font-weight-normal;
    text-align: center;
    border-top-left-radius: $border-radius-medium;
    border-top-right-radius: $border-radius-medium;
    background-color: $color-monochrome-100;
    line-height: $line-height-100;

    @include mq(large) {
        font-size: $font-size-150;
        padding-left: $space-100;
        padding-right: $space-100;
    }

    @each $color, $styles in $flag-badge-colors {
        &--#{$color} {
            background-color: map.get($styles, 'background');
            color: map.get($styles, 'color');

            &.#{$flag-badge-class-prefix}--inverse {
                background-color: map.get($styles, 'inverse', 'background');
                color: map.get($styles, 'inverse', 'color');
            }
        }
    }

    &--small {
        height: get-custom-property('flag-badge-small-height');
        padding-left: $space-75;
        padding-right: $space-75;
        font-size: $font-size-50;
        font-weight: $font-weight-bold;
    }

    &--fullwidth {
        width: 100%;
    }
}
