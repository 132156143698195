/* ==========================================================================
   #GENERIC
   ========================================================================== */

/* box sizing, * > * kind of selectors */

* {
    box-sizing: border-box;
}

/* disables outlines for mouse users */

.#{$ws10-css-prefix}-mouse-active {
    select,
    a,
    button {
        &:focus {
            outline: none;
        }
    }
}

/* clearing up all CMS added tags */

.#{$ws10-css-prefix}-reset-cms {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
        color: $inherit;
    }
}

/* Disable scrolling */
.#{$ws10-css-prefix}-no-scroll {
    position: fixed;
    overflow: hidden;
    width: 100%;
}

/* Reset inputs */
.#{$ws10-css-prefix}-input-component-reset {
    box-shadow: none;
    border-radius: 0;
    max-width: none;

    &:focus {
        border-width: 0;
        border-bottom-width: 1px;
    }
}
