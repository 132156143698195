@import '../styles/scss/precss/precss';
@import '../icon/icon';

$icon-text-class-prefix: 'ws10-icon-text';

.#{$icon-text-class-prefix} {
    @include ws10-text(false);

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: $space-100;

    &:last-child {
        margin-bottom: 0;
    }

    &--center {
        justify-content: center;
        text-align: center;
    }

    &--mid-render-icon {
        .#{$icon-text-class-prefix}__text {
            margin-left: $space-75;
        }
    }

    &__text {
        margin-left: $space-50;
        align-self: center;
        margin-bottom: 0;

        > p {
            margin-bottom: $space-50;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__icon-container {
        svg {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &--inverse {
        .#{$icon-text-class-prefix} {
            &__text {
                color: $color-monochrome-600-inverse;

                p {
                    color: inherit;
                }
            }
        }
    }
}
