/** @define item-summary-card */
@import '../styles/scss/precss/precss';

$item-summary-card-class-prefix: 'ws10-item-summary-card';

.#{$item-summary-card-class-prefix} {
    border-radius: $border-radius-medium;
    padding: $space-100;

    @include mq(medium) {
        padding: $space-125;
    }

    &--standalone {
        background-color: $color-monochrome-100;
        box-shadow: $shadow-medium;
    }

    &--nested {
        background-color: $color-monochrome-200;
    }

    @include ws10-text(false);

    display: flex;
    flex-direction: column;

    &__item {
        display: flex;
    }

    li {
        padding-bottom: 16px;
        border-bottom: 1px solid #bebebe;
    }

    li:not(:first-child) {
        padding-top: 16px;
    }

    ul > li:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }

    &__middle-container {
        flex: 1;
        margin-right: $space-75;
    }

    &__content--action-container {
        display: flex;
        flex-direction: column;
    }

    &__picture {
        min-width: 56px;
        margin-right: $space-75;
    }

    &__system-icon {
        margin-right: $space-75;
    }

    &__system-icon svg {
        margin-right: unset;
    }

    &__image {
        object-fit: contain;
        width: 56px;
        height: 56px;
    }

    &__headline {
        display: inline;
        margin-right: $space-50;
    }

    &__subline {
        margin-top: $space-25;

        &:last-child {
            margin-bottom: 0;
        }

        @include mq(medium) {
            margin-top: $space-50;
        }
    }

    &__copytext,
    &__text-link,
    &__button-link {
        margin-top: $space-75;

        @include mq(medium) {
            margin-top: $space-125;
        }
    }

    &__text-link,
    &__button-link {
        @include mq(medium) {
            margin-right: $space-100;
        }
    }

    &__tooltip-icon--trigger {
        position: relative;

        svg {
            position: absolute;
            top: -4px;

            @media (min-width: $breakpoint-medium) {
                top: -3px;
            }
        }
    }

    &__text-link-container {
        display: flex;

        @media (max-width: $breakpoint-medium) {
            flex-direction: column;
        }
    }

    &__button-link-container {
        display: flex;

        @media (max-width: $breakpoint-medium) {
            flex-direction: column;
        }
    }

    &__icon-button {
        margin-left: $space-75;
        padding-left: $space-75;
    }

    .#{$ws10-css-prefix}-button-icon-only--tertiary .#{$ws10-css-prefix}-button-icon-only__icon {
        color: $color-monochrome-600;
    }

    .ws10-text {
        margin-bottom: 0;
    }
}
