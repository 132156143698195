@import '../styles/scss/precss/precss';

$ws10-accordion-class-prefix: $ws10-css-prefix + '-accordion';

.#{$ws10-accordion-class-prefix} {
    @include ws10-text(false);

    &--flat {
        .#{$ws10-accordion-class-prefix}__item-details {
            border-top: $border-solid-grey;

            &:last-child {
                border-bottom: $border-solid-grey;
            }
        }
    }

    &--card {
        .#{$ws10-accordion-class-prefix}__item-details {
            margin-bottom: $space-100;
            border-radius: $border-radius-medium;
            background-color: $color-monochrome-100;
            box-shadow: $shadow-medium;

            .#{$ws10-accordion-class-prefix} {
                &__item-summary-content,
                &__item-content {
                    padding-left: $space-100;
                    padding-right: $space-100;

                    @include mq(small) {
                        padding-left: $space-150;
                        padding-right: $space-150;
                    }
                }
            }
        }
    }

    &__item-details {
        scroll-margin-top: calc(var(--navi-height, 0) + $space-100);

        &--open {
            .#{$ws10-accordion-class-prefix} {
                &__item-headline {
                    font-weight: $font-weight-bold;
                }

                &__item-chevron {
                    transform: rotate(180deg);
                }

                &__item-content {
                    height: auto;
                }
            }
        }
    }

    &__item-summary {
        line-height: $line-height-50;
        cursor: pointer;

        &::-webkit-details-marker {
            // hide native summary arrow in Safari
            display: none;
        }

        @media (hover: hover) {
            &:hover {
                .#{$ws10-accordion-class-prefix} {
                    &__item-headline {
                        color: $color-primary-200;
                    }

                    &__item-icon {
                        color: $color-primary-200;
                    }
                }
            }
        }

        &:focus-visible {
            @include brix-focus(false);
            .#{$ws10-accordion-class-prefix} {
                &__item-headline {
                    color: $color-primary-200;
                }

                &__item-icon {
                    color: $color-primary-200;
                }
            }
        }
    }

    &__item-summary-content {
        display: flex;
        align-items: center;
        padding: $space-125 $space-0;

        @include mq(small) {
            padding: $space-150 $space-0;
        }
    }

    &__item-chevron {
        transition: transform $duration-500 $ease-in-out-sine;
        transform-origin: center;
        color: $color-primary-200;
    }

    &__item-icon {
        margin-right: $space-100;
        color: $color-monochrome-600;
    }

    &__item-headline:not(h1, h2) {
        font-weight: normal;
    }

    &__item-headline {
        flex: 1 $zero;
        margin: $zero $space-100 $zero $zero;
        line-height: $line-height-100;
        color: $color-monochrome-600;

        @include mq(small) {
            span .#{$ws10-accordion-class-prefix}__item-headline {
                font-size: $font-size-200;
            }
        }
    }

    &__item-content {
        height: $zero;
        overflow: hidden;
        transition: height $duration-500 $ease-in-out-sine, visibility $duration-500 $ease-in-out-sine;
    }

    &__item-content-inner {
        padding-bottom: $space-125;

        @include mq(small) {
            padding-bottom: $space-150;
        }

        > :last-child,
        > ul:last-child > li:last-child,
        > ol:last-child > li:last-child {
            // remove margin-bottom of the last childs
            // stylelint-disable-next-line declaration-no-important
            margin-bottom: 0 !important;
        }
    }
}
