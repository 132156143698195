/** @define related-links */

@import '../styles/scss/precss/precss';

// See Design Tokens page in Brix Explorer for whats available (colors, fonts etc) or check in pre css

$related-links-class-prefix: $ws10-css-prefix + '-related-links';

.#{$related-links-class-prefix} {
    @include ws10-text(false);

    &__interaction-list {
        margin: $zero $zero $space-200 $zero;
    }

    &__headline {
        margin-bottom: $space-200;
        font-weight: $font-weight-light;
        line-height: $line-height-25;
        font-style: normal;
        margin-left: (-$space-50);
    }

    &__sub-headline {
        font-weight: $font-weight-bold;
        line-height: $line-height-25;
        font-style: normal;
        font-size: $font-size-200;
        margin-bottom: $space-125;
    }
}

.ws10-related-links .ws10-grid__inner .ws10-column.ws10-column--small-12:first-child {
    padding-left: $zero;
}

.ws10-related-links .ws10-grid__inner .ws10-column.ws10-column--small-12:last-child {
    padding-right: $zero;
}

@include mq(mobile-only) {
    .ws10-related-links .ws10-column.ws10-column--small-12.ws10-column.ws10-column--large-4.ws10-column.ws10-column--x-large-4 {
        padding-left: $zero;
        padding-right: $zero;
    }

    .ws10-related-links .ws10-grid__inner .ws10-column.ws10-column--small-12:first-child,
    .ws10-related-links .ws10-grid__inner .ws10-column.ws10-column--small-12:last-child {
        padding-left: $zero;
        padding-right: $zero;
    }
}

@include mq(medium) {
    .ws10-related-links .ws10-column.ws10-column--small-12.ws10-column.ws10-column--large-4.ws10-column.ws10-column--x-large-4 {
        padding-left: $zero;
        padding-right: $zero;
    }

    .ws10-related-links__headline {
        margin-left: (-$space-50);
    }
}

@include mq(large) {
    .ws10-related-links__headline {
        margin-bottom: $space-300;
        margin-left: (-$space-50);
    }

    .ws10-related-links__sub-headline {
        font-size: $font-size-300;
        margin-bottom: $space-150;
    }

    .ws10-related-links .ws10-grid__inner .ws10-column.ws10-column--small-12:first-child {
        padding-left: $zero !important;
    }

    .ws10-related-links .ws10-grid__inner .ws10-column.ws10-column--small-12:last-child {
        padding-right: $zero !important;
    }

    .ws10-related-links .ws10-column.ws10-column--small-12.ws10-column.ws10-column--large-4.ws10-column.ws10-column--x-large-4 {
        padding-left: $space-100;
        padding-right: $space-100;
    }

    .ws10-related-links .ws10-grid__inner {
        margin-left: (-$space-50);
    }
}

@include mq(x-large) {
    .ws10-related-links .ws10-related-links .ws10-grid__inner {
        margin-left: (-$space-50);
    }
}
