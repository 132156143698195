@import '../styles/scss/precss/precss';

$form-label-class-prefix: $ws10-css-prefix + '-form-label';

.#{$form-label-class-prefix} {
    @include ws10-text(false);

    color: $color-monochrome-600;
    transition: all $duration-200 $ease-in-sine;

    &--disabled {
        color: $color-monochrome-300;
        pointer-events: none;
    }

    &--error {
        color: $color-primary-200;
        pointer-events: none;
    }
}
