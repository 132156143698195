//  import only precss from styles package! This contains all your variables you need!
@import '../styles/scss/precss/precss';

$table-class-prefix: $ws10-css-prefix + '-hybrid-table';

@mixin tablet-view-small() {
    @media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
        @content;
    }
}

@mixin tablet-view-large() {
    @media only screen and (max-width: $breakpoint-large) {
        @content;
    }
}

.#{$table-class-prefix} {
    $full-width: 100%;

    @include ws10-text(false);

    display: table;
    table-layout: fixed;
    width: $full-width;
    margin-bottom: $space-100;
    overflow-x: scroll;
    border-radius: $border-radius-medium;
    box-shadow: $shadow-medium;
    background-color: $color-monochrome-100;

    @include mq(mobile-only) {
        width: $full-width;
    }

    //  Use this syntax for child elements
    &__card {
        padding: $space-200;
        table-layout: fixed;
        width: $full-width;

        @include mq(mobile-only) {
            padding: $space-100;
        }

        @include tablet-view-small {
            .#{$table-class-prefix}__tablet-small & {
                padding: $space-100;
            }
        }
    }

    &__row {
        display: table-row;
        padding: 0;
        border-bottom: $border-width-small $border-style-solid $color-monochrome-300;

        @include mq(mobile-only) {
            width: $full-width;
        }
    }

    &__table-cell {
        display: table-cell;
        border-top: $border-width-small $border-style-solid $color-monochrome-300;
        border-bottom: $border-width-small $border-style-solid $color-monochrome-300;
        padding: $space-100 $space-75;
        font-size: $font-size-100;
        line-height: $line-height-100;
        color: $color-monochrome-600;
        vertical-align: top;
        word-break: break-word;
        margin-right: $space-50;

        @include mq(small) {
            word-break: normal;
            padding: $space-150 $space-75;
        }

        @include mq(mobile-only) {
            width: $full-width;
            padding: $space-100 $space-75;
        }

        @include tablet-view-small {
            .#{$table-class-prefix}__tablet-small & {
                width: $full-width;
                padding: $space-100 $space-75;
            }
        }
    }

    &__tooltip {
        display: inline-flex;
        width: $space-200;
        max-height: $space-200;
        .#{$ws10-css-prefix}-tooltip {
            margin-right: $space-150;
            padding-top: $space-15;
        }

        &-icon {
            display: inline-block;
            width: $size-icon-150;
            height: $size-icon-150;

            &--left {
                margin: $space-25 $space-25 $space-25 $space-25;

                @include mq(mobile-only) {
                    margin: $space-15 $space-50 $space-15 $space-0;
                }
            }

            &--trigger {
                width: $space-200;
                height: $space-200;
                margin: $space-25 $space-25 $space-25 $space-25;

                @include mq(mobile-only) {
                    margin: $space-15 $space-0 $space-15 $space-50;
                }
            }

            &--trigger:focus {
                border-radius: $border-radius-half;
            }
        }
    }

    &__table-head {
        display: table-cell;
        font-size: $font-size-100;
        position: relative;
        border-bottom: $border-style-solid $border-width-small $color-monochrome-600;

        h5 {
            font-weight: $font-weight-bold;
            font-size: $font-size-100;
            line-height: $line-height-50;
            color: $color-monochrome-600;

            /* Default spacing from spec */
            margin-bottom: $space-50;
        }

        @include mq(mobile-only) {
            padding-bottom: $space-100;
            padding-top: $space-100;
            width: 40%;
        }

        @include tablet-view-small {
            .#{$table-class-prefix}__tablet-small & {
                padding-bottom: $space-100;
                padding-top: $space-100;
                width: 40%;
                h5.#{$ws10-css-prefix}-headline {
                    font-size: $font-size-150;
                }

                &:first-child {
                    padding-left: $space-100;
                }

                &:last-child {
                    padding-right: $space-200;
                    text-align: center;
                    width: 20%;
                }
            }
        }
        h5.#{$ws10-css-prefix}-headline {
            display: inline-block;
            margin: 0;
            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: keep-all;
            hyphens: manual;

            @include mq(mobile-only) {
                font-size: $font-size-100;
                line-height: $line-height-100;
            }

            @include mq(small) {
                font-size: $font-size-200;
                display: inline-block;
            }

            @media screen and (min-width: $breakpoint-small) and (max-width: $breakpoint-large) {
                // stylelint-disable-next-line declaration-no-important
                font-size: $font-size-150 !important;
            }
        }

        vertical-align: middle;
        padding-bottom: $space-200;
        padding-top: $space-200;
        text-align: center;

        &:first-child {
            text-align: left;
            padding-left: $space-200;

            @include mq(mobile-only) {
                padding-left: $space-100;
            }
        }

        &:last-child {
            padding-right: $space-200;

            @include mq(mobile-only) {
                padding-right: $space-200;
                text-align: center;
                width: 20%;
            }

            @include tablet-view-large {
                padding-left: $space-50;
            }
        }
    }

    &__table-content {
        @include mq(mobile-only) {
            width: $full-width;
        }

        @include mq(x-small) {
            width: 50%;
        }

        text-align: center;
        vertical-align: middle;

        &:first-child {
            text-align: left;
            padding-left: $space-200;
        }

        &:last-child {
            padding-right: $space-200;
        }
    }

    &__icon {
        position: relative;
        height: $size-icon-200;
        color: $color-primary-100;
        margin: 0 0 $space-25 0;
        size: $size-icon-200;

        &:hover {
            cursor: pointer;
        }

        // commented out because $focus-outline is box-shadow, not outline so this CSS never worked
        // &:focus {
        //     outline: $focus-outline;
        // }
        @media (max-width: $breakpoint-medium) {
            size: $space-150;
            margin: 0 0 $space-150 0;
        }
    }

    // to set space between every two consecutive icons
    &__icon-holder + &__icon-holder {
        margin: 0 0 0 $space-200;
    }

    &__icon-single {
        @include mq(mobile-only) {
            size: $size-icon-150;
            margin: 0 0 $space-25 0;
        }

        @include tablet-view-small {
            .#{$table-class-prefix}__tablet-small & {
                size: $size-icon-150;
                margin: 0 0 $space-25 0;
            }
        }

        position: relative;
        height: $size-icon-200;
        color: $color-primary-100;
        margin: 0 0 $space-25 0;
        size: $size-icon-200;

        &:hover {
            cursor: pointer;
        }

        // commented out because $focus-outline is box-shadow, not outline so this CSS never worked
        // &:focus {
        //     outline: $focus-outline;
        // }
    }

    &__sorting {
        display: inline-flex;
        width: $size-icon-150;
        color: $color-primary-100;
        margin-bottom: $space-50;

        &:hover {
            cursor: pointer;
        }

        // commented out because $focus-outline is box-shadow, not outline so this CSS never worked
        // &:focus {
        //     outline: $focus-outline;
        // }

        &__ascending,
        &__descending {
            display: none;
        }

        &--ascending &__ascending {
            display: inline-block;
            width: $size-icon-150;
            height: $size-icon-150;
        }

        &--descending &__descending {
            display: inline-block;
            width: $size-icon-150;
            height: $size-icon-150;
        }
    }

    &__row-desktop {
        @include mq(mobile-only) {
            display: none;
        }

        @include tablet-view-small {
            .#{$table-class-prefix}__tablet-small & {
                display: none;
            }
        }
    }

    &__row-mobile {
        @include mq(small) {
            display: none;
        }

        @include tablet-view-small {
            .#{$table-class-prefix}__tablet-small & {
                display: table-row;
            }
        }
    }

    &__accordion {
        margin: auto 0;
        border-top: $color-monochrome-500;

        // commented out because $focus-outline is box-shadow, not outline so this CSS never worked
        // &:focus {
        //     outline: $focus-outline;
        // }
        &-content {
            // stylelint-disable-next-line declaration-no-important
            padding-left: $space-100 !important;
            // stylelint-disable-next-line declaration-no-important
            padding-right: $space-100 !important;
        }
    }

    &__bills-accordion-item__label {
        width: 70%;
        text-align: center;
        margin: 0 $space-50 0 0;
    }

    &__bills-accordion-item__headline {
        width: $full-width;
        display: flex;
        align-items: center;
        padding: $space-100;
    }

    &__left-span {
        width: 40%;
    }

    &__inner-left-span {
        margin-right: $space-200;
    }

    &__right-span {
        width: 40%;
        text-align: center;
        align-items: center;
        justify-content: flex-end;
    }

    &__text-center {
        text-align: center;
    }

    &__m-100 {
        // stylelint-disable-next-line declaration-no-important
        margin-bottom: $space-100 !important;
        // stylelint-disable-next-line declaration-no-important
        margin-top: $space-100 !important;

        .#{$ws10-css-prefix}-icon-text {
            margin-bottom: 0;

            .#{$ws10-css-prefix}-icon__svg {
                color: $color-monochrome-600;
            }
        }

        .#{$ws10-css-prefix}-icon-text__text {
            text-align: center;
            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: keep-all;
            hyphens: manual;

            @include mq(small) {
                font-size: $font-size-150;
            }
        }

        .#{$ws10-css-prefix}-icon-text__icon {
            width: $size-icon-225;
            height: $size-icon-225;
        }
    }

    &__hidden {
        display: none;
    }

    &__text-limit {
        overflow: hidden;
        font-weight: inherit;
        // stylelint-disable-next-line declaration-no-important
        margin-bottom: 0 !important;

        @include mq(mobile-only) {
            white-space: normal;
            max-height: $space-300;
            font-size: $font-size-100;
        }

        @include mq(small) {
            white-space: normal;
            max-height: $space-300;
        }

        @include tablet-view-small {
            .#{$table-class-prefix}__tablet-small & {
                white-space: normal;
                max-height: $space-300;
                font-size: $font-size-150;
            }
        }
    }

    &__text {
        @include mq(mobile-only) {
            font-size: $font-size-100;
        }

        @include mq(small) {
            font-size: $font-size-150;
        }
    }

    &__chevron {
        width: 20%;
        text-align: end;
    }

    &__paginate {
        cursor: pointer;
        text-align: center;
        font-size: $font-size-100;
        font-weight: $font-weight-normal;
        color: $color-primary-100;
        line-height: $line-height-100;
        text-decoration: $text-decoration-underline;

        &:visited {
            color: $color-monochrome-400;
        }

        &:hover {
            color: $color-primary-100;
        }

        &:focus {
            color: $color-primary-100;

            // commented out because $focus-outline is box-shadow, not outline so this CSS never worked
            // outline: $focus-outline;
        }

        &:active {
            color: $color-primary-100;
        }

        padding: $space-100 $space-75;

        @include mq(small) {
            word-break: normal;
            padding: $space-150 $space-75;
        }

        @include mq(mobile-only) {
            width: $full-width;
            padding: $space-100 $space-75;
        }

        @include tablet-view-small {
            .#{$table-class-prefix}__tablet-small & {
                width: $full-width;
                padding: $space-100 $space-75;
            }
        }

        &__show-more,
        &__show-less {
            display: none;
        }

        &--show-more &__show-more {
            display: block;
        }

        &--show-less &__show-less {
            display: block;
        }
    }
}
