/** @define unordered-horizontal-list; weak */

@import '../styles/scss/precss/precss';

// See Design Tokens page in Brix for whats available or check in pre css

$unordered-horizontal-list-class-prefix: $ws10-css-prefix + '-unordered-horizontal-list';

.#{$unordered-horizontal-list-class-prefix} {
    @include ws10-text(false);

    @include mq(small) {
        margin-bottom: $space-100;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .#{$unordered-horizontal-list-class-prefix} {
        // the nesting is done to increase CSS specificity
        // so that the 'ws10-text' class is not able to overwrite
        // our component styles

        &__headline {
            @include ws10-text-small;

            display: block;
            margin-right: $space-100;
            margin-bottom: $space-50;
            font-weight: $font-weight-bold;

            @include mq(small) {
                display: inline-block;
                margin-bottom: $zero;
            }

            .#{$unordered-horizontal-list-class-prefix}--break-after-headline & {
                display: block;
            }

            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                @include ws10-h5;

                margin-bottom: $space-0;
                color: inherit;

                @include mq(small) {
                    /* overwrite h5 lineheight to match lineheight of elements on the left */
                    line-height: $line-height-100;
                }
            }

            &--inverse {
                color: $color-monochrome-600-inverse;
            }
        }

        &__list {
            @include reset-element;
            @include reset-list;

            display: inline-block;
            margin-bottom: $zero;
        }

        &__item {
            @include reset-element;

            display: block;
            font-size: $font-size-100;
            line-height: $line-height-100;

            // important is needed because in my case the .ws10-text class from scripts-repo overwrites the regular CSS
            margin-bottom: $space-50;

            a {
                &:focus {
                    @include brix-focus(false);
                }

                &:focus-visible {
                    outline: none;
                }

                &:focus:not(:focus-visible) {
                    box-shadow: none;
                }
            }

            @include mq(small) {
                display: inline;
            }

            + .#{$unordered-horizontal-list-class-prefix}__item {
                @include mq(small) {
                    margin-left: $space-100;
                }
            }
        }
    }
}
