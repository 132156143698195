@import '../styles/scss/precss/precss';

$form-element-block-class-prefix: $ws10-css-prefix + '-form-element-block';
$form-element-block-error-message-class: $form-element-block-class-prefix + '__error-message';
$form-element-block-helper-text-class: $form-element-block-class-prefix + '__helper-text';

.#{$form-element-block-class-prefix} {
    @include ws10-text(false);

    display: block;

    .#{$form-element-block-class-prefix} {
        // the nesting is done to increase CSS specificity
        // so that the 'ws10-text-smaller' class is not able to overwrite
        // our component styles

        &__label-container {
            display: block;
            margin-bottom: $space-50;
        }

        &__input-container {
            position: relative;
        }

        &__helper-text {
            display: block;
            color: $color-monochrome-600;
        }

        &__error-message {
            display: none;
            color: $color-primary-200;
        }

        &__loading-animation {
            display: none;
        }
    }

    &--text-input {
        .#{$form-element-block-class-prefix} {
            &__loading-animation {
                display: block;
                position: absolute;
                top: 50%;
                right: $space-400;
                transform: translateY(-50%);

                @include mq(medium) {
                    right: $space-500;
                }
            }
        }
    }

    &--error {
        .#{$form-element-block-error-message-class} {
            display: block;
        }

        .#{$form-element-block-helper-text-class} {
            display: none;
        }
    }

    &--warn {
        .#{$form-element-block-error-message-class} {
            display: block;
            color: $color-monochrome-600;
        }

        .#{$form-element-block-helper-text-class} {
            display: none;
        }
    }

    // &--success {
    // For future reference
    // }

    &--disabled {
        cursor: not-allowed;

        .#{$form-element-block-helper-text-class} {
            color: $color-monochrome-300;
        }
    }
}
