@import '../styles/scss/precss/precss';
@import '../form-text-input/form-text-input';
@import '../form-element/form-element';

$form-suggest-input-class-prefix: 'ws10-form-suggest-input';

.#{$form-suggest-input-class-prefix} {
    @include ws10-text(false);

    position: relative;
    display: flex;
    flex-direction: column;

    &--focused {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 56;

        @include mq(small) {
            z-index: auto;
        }
    }

    &__container {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__input {
        padding: 0;

        @at-root {
            .#{$form-suggest-input-class-prefix}--focused & {
                padding: $space-100 $space-100 $space-0;
            }
        }
    }

    &__result-wrapper {
        position: relative;
        display: none;
        overflow-y: auto;

        @at-root {
            .#{$form-suggest-input-class-prefix}--focused & {
                display: block;
            }
        }
    }

    &__result {
        position: relative;

        @include ws10-text;

        overflow-y: auto;
        background: $color-monochrome-100;
    }

    &__result-item {
        position: relative;
        padding: $space-75 $space-200;
        line-height: $line-height-fix-150;
        cursor: pointer;

        &--active {
            background: $color-monochrome-200;
        }

        &__icon {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            left: $space-200;
            width: $size-icon-150;
            height: $size-icon-150;
        }

        @at-root {
            .#{$form-suggest-input-class-prefix}--iconized & {
                padding-left: $space-200 + $size-icon-150 + $space-75;
            }
        }
    }

    .#{$ws10-css-prefix}-form-text-input__system_icon-container {
        .#{$ws10-css-prefix}-system-icon {
            display: none;
            cursor: pointer;
        }
    }

    &--has-results,
    &--focused {
        .#{$ws10-css-prefix}-form-text-input__system_icon-container {
            .#{$ws10-css-prefix}-system-icon {
                display: block;
            }
        }
    }

    @include mq(small) {
        &--focused {
            position: relative;
        }

        &__container {
            display: block;
        }

        &__input {
            @at-root {
                .#{$form-suggest-input-class-prefix}--focused & {
                    padding: 0;
                }
            }
        }

        &__result-wrapper {
            position: absolute;
            display: block;
            top: calc(100% - #{$space-25});
            left: 0;
            margin-left: -$space-50;
            width: calc(100% + #{$space-100});
            overflow: hidden;
            max-height: 0;
            transition: max-height $duration-200 $ease-in-out-sine;
            z-index: 5;

            @at-root {
                .#{$form-suggest-input-class-prefix}--focused & {
                    max-height: 710px;
                }
            }
        }

        &__result {
            margin: $space-25 $space-50 $space-100;
            border-radius: $border-radius-medium;
            box-shadow: $shadow-large;

            @at-root {
                .#{$form-suggest-input-class-prefix}--limited & {
                    max-height: 320px;
                }
            }
        }

        &__result-item {
            padding-left: $space-100;

            &__icon {
                left: $space-100;
            }

            @at-root {
                .#{$form-suggest-input-class-prefix}--iconized & {
                    padding-left: $space-100 + $space-150 + $space-75;
                }
            }
        }
    }

    @include mq(mobile-only) {
        &--focused {
            background: $color-monochrome-100;
        }
    }
}
