@import '../styles/scss/precss/precss';

$image-header-class-prefix: $ws10-css-prefix + '-image-header';
$image-header-mobile-height: 432px;
$image-header-medium-height: 362px;

// not a design token, requested by UX
$image-header-max-width: 2560px;

// not a design token, requested by UX
$image-header-img-mobile-split-height: 210px;

.#{$image-header-class-prefix} {
    @include ws10-text(false);

    position: relative;
    width: 100%;
    max-width: $image-header-max-width;
    margin: 0;
    padding: 0;
    height: $image-header-mobile-height;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-medium) {
        flex-direction: row;

        & .#{$image-header-class-prefix}__image-text-container {
            width: 100%;
        }

        &--medium {
            height: $image-header-medium-height;
        }

        &:not(.#{$image-header-class-prefix}--extrasmall) {
            .#{$ws10-css-prefix}-grid {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }

    &--split {
        height: auto;
        background: $color-monochrome-100;

        @media screen and (min-width: $breakpoint-medium) {
            & .#{$image-header-class-prefix}__image-text-container {
                margin: 0 auto;
                max-width: $breakpoint-x-large;
            }

            height: $image-header-mobile-height;

            &.#{$image-header-class-prefix}--medium {
                height: $image-header-medium-height;
            }

            &.#{$image-header-class-prefix}--large {
                height: $image-header-mobile-height;
            }

            flex-direction: row;

            &.#{$image-header-class-prefix}--7 {
                .#{$image-header-class-prefix}__split-container {
                    width: 60%;
                }

                .#{$image-header-class-prefix}__img {
                    margin-left: 60%;
                    object-position: left center;
                    width: auto;
                }
            }

            &.#{$image-header-class-prefix}--6 {
                .#{$image-header-class-prefix}__split-container {
                    width: 50%;
                }

                .#{$image-header-class-prefix}__img {
                    margin-left: 50%;
                    object-position: left center;
                    width: inherit;
                }
            }

            &.#{$image-header-class-prefix}--5 {
                .#{$image-header-class-prefix}__split-container {
                    width: 40%;
                }

                .#{$image-header-class-prefix}__img {
                    margin-left: 40%;
                    object-position: left center;
                    width: auto;
                }
            }

            &.#{$image-header-class-prefix}--right {
                flex-direction: row-reverse;

                &.#{$image-header-class-prefix}--7 {
                    .#{$image-header-class-prefix}__split-container {
                        margin-left: auto;
                    }

                    .#{$image-header-class-prefix}__img {
                        object-position: right center;
                        width: 90%;
                        margin-left: -50%;
                    }
                }

                &.#{$image-header-class-prefix}--6 {
                    .#{$image-header-class-prefix}__split-container {
                        margin-left: auto;
                    }

                    .#{$image-header-class-prefix}__img {
                        margin-left: -50%;
                        object-position: right center;
                        width: inherit;
                    }
                }

                &.#{$image-header-class-prefix}--5 {
                    .#{$image-header-class-prefix}__split-container {
                        margin-left: auto;
                    }

                    .#{$image-header-class-prefix}__img {
                        object-position: right center;
                        margin-left: -50%;
                        width: 110%;
                    }
                }
            }
        }
    }

    &__picture {
        .#{$image-header-class-prefix}--split & {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__img,
    &__small-img {
        height: $image-header-mobile-height;
        width: 100%;
        margin: 0;
        padding: 0;
        object-fit: cover;
        object-position: top;

        .#{$image-header-class-prefix}--split & {
            height: $image-header-img-mobile-split-height;
            object-position: center center;
        }

        @media screen and (min-width: $breakpoint-medium) {
            .#{$image-header-class-prefix}--medium & {
                height: $image-header-medium-height;
            }

            .#{$image-header-class-prefix}--large & {
                height: $image-header-mobile-height;
            }
        }
    }

    &__img {
        @media screen and (min-width: $breakpoint-medium) {
            .#{$image-header-class-prefix}--split & {
                width: inherit;
            }
        }
    }

    &__small-img {
        @media screen and (min-width: $breakpoint-medium) {
            display: none;
        }
    }

    &__image-container {
        .#{$image-header-class-prefix}--split & {
            height: $image-header-img-mobile-split-height;
            max-width: 1280px;
        }

        @media screen and (min-width: $breakpoint-medium) {
            width: 100%;
            position: absolute;

            .#{$image-header-class-prefix}--medium & {
                height: $image-header-medium-height;
            }

            .#{$image-header-class-prefix}--large & {
                height: $image-header-mobile-height;
            }
        }
    }

    .#{$ws10-css-prefix}-belt-container {
        @media screen and (min-width: $breakpoint-medium) {
            position: relative;
            width: 100%;
        }
    }

    &__content-container {
        text-align: center;
        background: rgba($color-monochrome-600, $opacity-60);

        &--solid {
            background: $color-monochrome-500;
        }

        @media screen and (min-width: $breakpoint-large) {
            &.#{$ws10-css-prefix}-grid__inner {
                margin-top: $zero !important;
            }
        }

        padding: $space-150 $zero;
        margin: $zero !important;
        bottom: $zero;
        left: $zero;

        .#{$image-header-class-prefix}--split & {
            position: relative;
            background: $color-monochrome-100;
        }

        @media screen and (min-width: $breakpoint-medium) {
            background: transparent;
            text-align: left;
            padding: 0;

            .#{$image-header-class-prefix}--split & {
                bottom: 0;
            }

            .#{$image-header-class-prefix}--medium & {
                height: $image-header-medium-height;
            }

            .#{$image-header-class-prefix}--large & {
                height: $image-header-mobile-height;
            }

            .#{$image-header-class-prefix}:not(.#{$image-header-class-prefix}--extrasmall) & {
                .#{$ws10-css-prefix}-column {
                    padding-top: 0;
                }
            }

            .#{$image-header-class-prefix}:not(.#{$image-header-class-prefix}--split) & {
                width: calc(100% + 16px);
                display: flex;
            }

            .#{$image-header-class-prefix}--left:not(.#{$image-header-class-prefix}--split) & {
                justify-content: flex-start;
            }

            .#{$image-header-class-prefix}--right:not(.#{$image-header-class-prefix}--split) & {
                justify-content: flex-end;
            }
        }

        @media screen and (max-width: $breakpoint-medium) {
            width: 100%;
            position: absolute;
        }

        @media screen and (width: $breakpoint-medium) {
            top: 0;
        }
    }

    &__content {
        @media screen and (max-width: $breakpoint-medium) {
            .#{$image-header-class-prefix} & {
                padding: 0 $space-100;
            }
        }

        @media screen and (min-width: $breakpoint-medium) {
            .#{$image-header-class-prefix}:not(.#{$image-header-class-prefix}--extrasmall):not(.#{$image-header-class-prefix}--split) & {
                margin: auto $space-150 auto $zero;
            }

            // causes tooltip bubble position issue

            .#{$image-header-class-prefix}--split & {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        @media screen and (min-width: $breakpoint-medium) {
            .#{$image-header-class-prefix}--split & {
                padding-left: $space-100;
                padding-right: $space-100;
            }
        }

        @media screen and (min-width: $breakpoint-large) {
            .#{$image-header-class-prefix}--split & {
                padding-left: $space-100;
                padding-right: $space-200;
            }

            .#{$image-header-class-prefix}--split.#{$image-header-class-prefix}--right & {
                padding-left: $space-200;
                padding-right: $space-100;
            }
        }

        @media screen and (min-width: $breakpoint-x-large) {
            .#{$image-header-class-prefix}--split & {
                padding-left: $space-125;
                padding-right: $space-200;
            }

            .#{$image-header-class-prefix}--split.#{$image-header-class-prefix}--right & {
                padding-left: $space-200;
                padding-right: $space-125;
            }
        }
    }

    &__highlight-badge {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -15px;

        @media screen and (min-width: $breakpoint-medium) {
            position: static;
            transform: none;

            &+.#{$image-header-class-prefix}__headline-container {
                padding-top: $space-100;
            }
        }
    }

    &__headline-container {
        margin-left: $space-50;
        margin-right: $space-50;

        @media screen and (min-width: $breakpoint-medium) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__headline,
    &__subline {
        & > *,
        .ws10-headline {
            margin: 0;
            color: $color-monochrome-100;
        }

        .#{$image-header-class-prefix}--split & {
            & > * {
                color: $color-monochrome-600;
            }
        }

        @media screen and (min-width: $breakpoint-medium) {
            &--black {
                & > *,
                .ws10-headline {
                    color: $color-monochrome-600;
                }
            }
        }
    }

    &__subline {
        margin-top: $space-75;
    }

    &__promo-price-container {
        padding-top: $space-100;
        display: flex;

        @include mq(medium) {
            justify-content: start;
        }

        justify-content: center;
        text-align: left;
    }

    &__buttons-container {
        display: flex;
        flex-direction: column;
        margin-top: $space-150;

        &--no-margin {
            margin-top: $zero;
        }

        @include mq(small) {
            flex-direction: row;
        }

        @media (min-width: $breakpoint-small) and (max-width: $breakpoint-medium) {
            justify-content: center;
        }

        @media (width: $breakpoint-medium) {
            justify-content: flex-start;
        }

        > :nth-child(2) {
            @media screen and (max-width: $breakpoint-small) {
                margin-top: $space-100;
            }

            @include mq(small) {
                margin-left: $space-100;
                margin-top: $zero;
            }
        }
    }
}
